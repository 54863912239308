import commonTypes from "../common/commonTypes"

import { getUsersAnalytics, usersAnalytics } from '../common/usersData'

import groupsSheet from '../common/groupsSheet'

export default {
	types: commonTypes,
	remoteData: {
		journeys: {
			module: 'Journey',
			action: 'getList',
		},
		groups: {
			module: 'Promotion',
			action: 'getList',
			state: 'list',
		},
		groupsData: {
			module: 'Logs',
			action: 'GetGroupsTrackingData',
		},
		users: {
			module: 'Logs',
			action: 'GetUsersTrackingData',
			payload: {
				roles: ['user'],
			},
		},
		customers: {
			module: 'Customers',
			action: 'getList',
			state: 'list',
			groupByUniq: 'id',
		},
	},
	extraData: {
		usersData: (data) => usersAnalytics(getUsersAnalytics(data.users)),
		usersByGroupId(data) {
			return data.users.reduce((dict, user) => {
				user.groups.forEach((userGroup) => {
					if (!dict[userGroup.group_id]) {
						dict[userGroup.group_id] = []
					}

					dict[userGroup.group_id].push(user)
				})

				return dict
			}, {})
		},
		groupsSortedByCustomer(data) {
			return data.groups.sort((groupA, groupB) => {
				if (!groupB.customer_id || groupA.customer_id < groupB.customer_id)
					return -1
				if (!groupA.customer_id || groupA.customer_id > groupB.customer_id)
					return 1

				return 0
			})
		},
	},
	sheets: [
		groupsSheet,
	]
}