import apollo from "@/apolloClient";

import {
  GC_GET_PROMOTION_TYPE_LIST,
  GC_GET_PROMOTION_LIST,
  GC_GET_PROMOTION_BY_ID,
  GC_ADD_PROMOTION_ONE,
  GC_UPDATE_PROMOTION_BY_ID,
  GC_DELETE_PROMOTION_BY_ID,
  GC_GET_INTERNS_EXTRA_DATA,
} from "@/graphql/promotion";

export default {
  namespaced: true,
  state: {
    list: [],
    items: {},
    typeList: [],
  },
  mutations: {
    SET_PROMOTION_LIST(state, promotions) {
      state.list = promotions || [];
    },
    SET_PROMOTION_TYPE_LIST(state, types) {
      state.typeList = types;
    },
    SET_PROMOTION(state, promotion) {
      if (!promotion || !promotion.id) return;

      state.items[promotion.id] = promotion;
    },
  },
  actions: {
    async getTypeList({ commit }) {
      const response = await apollo.query({
        query: GC_GET_PROMOTION_TYPE_LIST,
      });

      commit("SET_PROMOTION_TYPE_LIST", response.data.group_type);
    },
    async getList({ commit }) {
      const response = await apollo.query({ query: GC_GET_PROMOTION_LIST });

      commit("SET_PROMOTION_LIST", response.data.group);
    },
    async getByID({ commit }, id) {
      let response = await apollo.query({
        query: GC_GET_PROMOTION_BY_ID,
        variables: { id },
      });

      if (!response.data.group_by_pk) {
        return;
      }

      commit("SET_PROMOTION", response.data.group_by_pk);
    },
    async getInternsExtraData(context, { id, internIds }) {
      const response = await apollo.query({
        query: GC_GET_INTERNS_EXTRA_DATA,
        variables: { id, internIds }
      })

      return (response?.data?.user || [])
    },
    async save(context, { data, journeys }) {
      let response = null;
      let result = {};

      let promotion = {
        identifier: data.identifier,
        name: data.name,
        start_date: data.startDate,
        end_date: data.endDate,
        course_id: data.course,
        customer_id: data.customer_id,
        promotion_type_id: data.type,
        disabled: data.disabled,
        awkn_journey_id: data.awknJourneyId,
      };

      // Update or add the promotion
      if (data.id) {
        // Set id
        promotion.id = data.id;

        // Get current sequences association and their ids
        promotion.sequences = [];
        // promotion.sequences_ids = []

        // todo/note : shouldn't this be done in the caller ? (ie I don't have to know/don't care how you structure your data... I need MY format :p)
        // todo: compare to old db data and uniquIFY whith course sequences data ?? (or in the caller ?)
        data.sequences.forEach((sequence) => {
          // promotion.sequences_ids.push(sequence.id)

          promotion.sequences.push({
            group_id: data.id,
            sequence_id: sequence.id,
            user_id: sequence.userID || null,
            start_date: sequence.startDate,
            end_date: sequence.endDate,
          });
        });

        // Get current users association and their ids
        promotion.users = [];
        promotion.users_ids = [];

        data.users.forEach((user) => {
          promotion.users_ids.push(user.id);

          promotion.users.push({
            group_id: data.id,
            user_id: user.id,
          });
        });

        // Update remote data
        response = await apollo.mutate({
          mutation: GC_UPDATE_PROMOTION_BY_ID,
          variables: promotion,
        });

        result.success = true;
        result.id = response.data.update_group_by_pk.id;

        if (promotion.awkn_journey_id) {
          // Update journey group name and state
          await context.dispatch("Journey/update", {
            id: promotion.awkn_journey_id,
            group_name: promotion.name,
            active_status: (promotion.disabled ? 0 : 1),
          }, { root: true });
        }
      } else {
        // Create Journey from template id
        if (data.awknJourneyTemplateId) {
          const journeyTemplate = journeys.find((journey) => journey.id === data.awknJourneyTemplateId)

          if (journeyTemplate) {
            // Duplicate journey template
            const journey = await context.dispatch("Journey/duplicate", {
              id: data.awknJourneyTemplateId,
              training_name: journeyTemplate.training_name,
              group_name: data.name,
              start_date: data.startDate,
            }, { root: true });

            // Set group journey id
            if (journey) {
              promotion.awkn_journey_id = journey.id
            }
          }
        }

        // Get current sequences association
        promotion.sequences = data.sequences.map((sequence) => {
          return {
            sequence_id: sequence.id,
            user_id: sequence.userID || null,
            start_date: sequence.startDate,
            end_date: sequence.endDate,
          };
        });

        // Get current users association
        promotion.users = data.users.map((user) => ({ user_id: user.id }));

        // Add remote data
        response = await apollo.mutate({
          mutation: GC_ADD_PROMOTION_ONE,
          variables: promotion,
        });

        result.id = response.data.insert_group_one.id;
      }

      // Update journey users
      await context.dispatch("Journey/updateContacts", {
        id: promotion.awkn_journey_id,
        users: data.users,
      }, { root: true });

      await context.dispatch("getByID", result.id);

      return result;
    },

    async delete(context, id) {
      // Disable the journey
      const group = context.state.items[id]

      if (group.awkn_journey_id) {
        await context.dispatch("Journey/update", {
          id: group.awkn_journey_id,
          active_status: 0,
        }, { root: true });
      }

      // Delete the group
      const response = await apollo.mutate({
        mutation: GC_DELETE_PROMOTION_BY_ID,
        variables: { id },
      });

      return response.data.delete_group_by_pk;
    },
  },
};
