import { ApolloClient } from "apollo-client";

import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

import { ApolloLink, fromPromise } from "apollo-link";
import { onError } from "apollo-link-error";

import store from "@/store/index";

const httpLink = createHttpLink({ uri: "https://back.rxs.live/v1/graphql" });

/* Handle authorization header */
const authLink = new ApolloLink((operation, forward) => {
  const token = store.getters["Auth/userToken"];

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    },
  });

  return forward(operation);
});

/* Handle authorization error */
const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  console.error(graphQLErrors);
});

/* Automagicly remove __typename from all operation variables */
function omitTypename(key, value) {
  return key === "__typename" ? undefined : value;
}

const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }

  return forward(operation);
});

const link = new ApolloLink.from([
  omitTypenameLink,
  errorLink,
  authLink,
  httpLink,
]);

const cache = new InMemoryCache();

const client = new ApolloClient({
  link: link,
  cache,
});

client.defaultOptions = {
  query: {
    fetchPolicy: "no-cache",
  },
};

export default client;
