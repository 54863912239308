import commonTypes from "../common/commonTypes"

export default {
	types: commonTypes,
	remoteData: {
		user: {
			module: 'Logs',
			action: 'GetUserTrackingData',
		},
	},
	extraData: {},
}