import commonTypes from "../common/commonTypes"

import { journeysRates } from "../common/journeysData"
import { groupAnalytics } from '../common/groupsData'
import { getUsersAnalytics, usersAnalytics } from '../common/usersData'

export default {
	types: commonTypes,
	remoteData: {
		journeys: {
			module: 'Journey',
			action: 'getList',
		},
		groups: {
			module: 'Logs',
			action: 'GetCustomerGroupsTrackingData',
		},
		users: {
			module: 'Logs',
			action: 'GetCustomerUsersTrackingData',
			payload: {
				roles: ['user'],
			},
		},
	},
	extraData: {
		groupsRates(data) {
			// Filter journey to only use the customer ones
			const groups = data.groups.list.reduce((dict, group) => {
				dict[group.id] = true
				return dict
			}, {})

			const journeys = data.journeys.filter((journey) => {
				if (!journey.group?.id) {
					return false
				}

				return !!groups[journey.group.id]
			})

			return journeysRates(journeys)
		},
		groupsAnalytics(data) {
			const groupUsersAnalytics = data.users.reduce((dict, user) => {
				if (!user.user_logs) {
					return dict
				}

				user.user_logs.forEach((log) => {
					if (!dict[log.data_key]) {
						dict[log.data_key] = []
					}

					dict[log.data_key].push(log.data)
				})

				return dict
			}, {})

			return data.groups.list.reduce((dict, group) => {
				dict[group.id] = groupAnalytics(groupUsersAnalytics[group.id] || [])

				return dict
			}, {})
		},
		usersData: (data) => usersAnalytics(getUsersAnalytics(data.users)),
	},
	sheets: []
}