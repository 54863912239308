import { render, staticRenderFns } from "./PromotionInternsForm.vue?vue&type=template&id=4f8737c2"
import script from "./PromotionInternsForm.vue?vue&type=script&lang=js"
export * from "./PromotionInternsForm.vue?vue&type=script&lang=js"
import style0 from "./PromotionInternsForm.vue?vue&type=style&index=0&id=4f8737c2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports