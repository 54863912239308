<template>
	<div class="dashboard-main">
		<!-- Header -->
		<div class="flex items-center justify-between">
			<div class="mb-4">
				<Toggle
				v-if="isSuperAdmin"
				:checked="includeOtherRoles"
				:onInput="() => includeOtherRoles = !includeOtherRoles"></Toggle>
				<span v-if="isSuperAdmin" class="ml-2">Inclure les admins/responsables clients</span>
			</div>
			<button type="button" class="mb-4 twn-button" @click="ExportReport" v-bind:disabled="isLoading">
				<span v-if="!isLoading">
					Exporter le rapport
				</span>
				<span v-else>
					Chargement...
				</span>
			</button>
		</div>

		<!-- Dashboard -->
		<p v-if="isLoading || !dashboardData">
			Chargement...
		</p>
		<div v-else>
			<CategoryBlock title="Statistiques AWKN">
				<!-- Group and user KPIs -->
				<div class="dashboard-main-kpis mb-8">
					<KPIBlock
						title="Promotions"
						:number="dashboardData.groupsData.total"
					/>
					<KPIBlock
						title="Promotions ouvertes"
						:number="dashboardData.groupsData.opened"
						backgroundColor="bg-dashboard-blue-dark"
					/>
					<KPIBlock
						title="Utilisateurs inscrits"
						:number="dashboardData.users.length"
					/>
					<KPIBlock
						title="Utilisateurs actifs"
						:number="dashboardData.usersData.activeCount"
						backgroundColor="bg-dashboard-green"
						help="Nombre de stagiaires ayant ouvert au moins une capsule."
					/>
				</div>

				<!-- User open/click rates KPIs -->
				<div class="dashboard-main-kpis">
					<KPIBlock
						title="Taux d’ouverture"
						:percentage="dashboardData.usersData.openRate"
						help="Nombre d'ouvertures uniques divisé par le nombre d'emails envoyés hors relance."
					/>
					<KPIBlock
						title="Taux de clic"
						:percentage="dashboardData.usersData.clickRate"
						help="Nombre de clics uniques divisé par le nombre de capsules cliquables envoyées."
					/>
				</div>
			</CategoryBlock>
		</div>
	</div>
</template>

<script>
	import trackingReport from '@/utils/trackingReport'

	import template from "@/constants/mainTrackingReportTemplate"

	import Toggle from '@/components/Toggle'

	import CategoryBlock from "./block/Category"
	import KPIBlock from "./block/KPI"

	export default {
		name: 'MainDashboard',
		components: {
			Toggle,
			CategoryBlock,
			KPIBlock,
		},
		data() {
			return {
				isLoading: false,
				dashboardData: null,
				includeOtherRoles: false,
			}
		},
		computed: {
			isSuperAdmin() {
				const userInfo = this.$store.state.Auth.userInfo

				return (userInfo && userInfo.role == 'superadmin')
			},
		},
		watch: {
			async includeOtherRoles(includeOtherRoles) {
				this.isLoading = true

				// Load tracking data
				let payload = null

				if (includeOtherRoles) {
					payload = { roles: ['user', 'superadmin', 'customer_manager'] }
				}

				this.dashboardData = await trackingReport.loadData(this.$store, template, payload)

				this.isLoading = false
			}
		},
		async mounted() {
			this.isLoading = true

			// Load tracking data
			this.dashboardData = await trackingReport.loadData(this.$store, template)

			this.isLoading = false
		},
		methods: {
			async ExportReport() {
				this.isLoading = true
				
				try {
					await trackingReport.export(this.$store, template)
				} catch (err) {
					console.error(err)
				}

				this.isLoading = false
			}
		}
	}
</script>

<style lang="scss">
	.dashboard-main {
		@apply w-full max-w-screen-lg mx-auto;

		&-kpis {
			@apply flex flex-wrap justify-between;
		}

		.dashboard-category {
			@apply w-full mb-5;

			.dashboard-kpi {
				@apply flex-1 mx-4;

				&:first-child {
					@apply ml-0;
				}

				&:last-child {
					@apply mr-0;
				}
			}
		}

		&-active-user {
			& > p {
				display: inline-block;
			}
		}

		&-finish-by-job {
			@apply flex;
			padding-left: 100px;

			.dashboard-chart {
				@apply pr-8 mb-3;
			}

			& > div:first-child {
				margin-left: -100px;

				p.ct-label {
					margin-left: 100px;
				}
			}

			p.ct-label {
				@apply text-dashboard-blue-dark;

				font-style: italic;
			}
		}
	}
</style>