import gql from 'graphql-tag'

const CG_GET_ORGAS = gql`
query GetOrgas{
  organisations {
    id
    name
  }
}`

// const CG_GET_STATUS = gql`
// query GetStatus{
//     user_aggregate(distinct_on: experienced) {
//         nodes {
//             experienced
//         }
//     }
// }`

const GC_GET_USERS = gql`
query GetUsers{
    user {
      id
      name: last_name
      first_name
      email
      role
      disabled
      created_at
      updated_at
      metas {
        id
        value
        meta_type_id
      }
      groups {
        group_id
        group {
          awkn_journey_id
        }
      }
    }
  }`

const GC_GET_FEREDATIONS = gql`
query GetFederations{
  federations{
    id
    name
    slug
  }
}
`

const CG_GET_META_TYPES = gql`
query GetMetaTypes{
  meta_type {
    id
    slug
  }
}`

export {
    CG_GET_ORGAS,
    // CG_GET_STATUS,
    GC_GET_USERS,
    GC_GET_FEREDATIONS,
    CG_GET_META_TYPES
}
