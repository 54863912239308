import { computeTrackingDataForList, countMatch, averageField } from '@/utils/trackingDataFactories'

export function getUsersAnalytics(users) {
	// Flatten user analytics
	return users.reduce((list, user) => {
		if (!user.user_logs) {
			return list
		}

		user.user_logs.forEach((log) => {
			list.push(log.data)
		})

		return list
	}, [])
}

export function usersAnalytics(usersAnalytics) {
	// Compute active user count, user time average and max sent steps
	const analytics = computeTrackingDataForList(usersAnalytics, {
		activeCount: countMatch(analyticsData => (analyticsData.click_rate > 0)),
		averageTimeInSeconds: averageField('time_spent_in_seconds', null, (value => (value > 0))),
		openRate: averageField('open_rate'),
		clickRate: averageField('click_rate'),
	})

	return analytics
}