import apollo from '@/apolloClient'

import {
    GC_GET_NARRATIVE_TYPE_LIST,
    GC_GET_NARRATIVE_LIST,
    GC_GET_NARRATIVE_BY_ID,
    GC_ADD_NARRATIVE_ONE,
    GC_UPDATE_NARRATIVE_BY_ID,
    GC_DELETE_NARRATIVE_BY_ID,
    GC_GET_SLIDE_TYPE_LIST
} from '@/graphql/narrative'

export default {
    namespaced: true,
    state: {
        list: [],
        items: {},
        typeList: [],
        slideTypeList: [],
    },
    mutations: {
        SET_NARRATIVE_LIST(state, narratives) {
            state.list = (narratives || []);
        },
        SET_NARRATIVE_TYPE_LIST(state, types) {
            state.typeList = types;
        },
        SET_NARRATIVE(state, narrative) {
            if (!narrative || !narrative.id)
                return

            state.items[narrative.id] = narrative
        },
        SET_SLIDE_TYPE_LIST(state, types) {
            state.slideTypeList = types;
        }
    },
    actions: {
        async getTypeList({ commit }) {
            const response = await apollo.query({ query: GC_GET_NARRATIVE_TYPE_LIST })

            commit('SET_NARRATIVE_TYPE_LIST', response.data.narrative_type || [])
        },
        async getSlideTypeList({ commit }) {
            const response = await apollo.query({ query: GC_GET_SLIDE_TYPE_LIST })

            commit('SET_SLIDE_TYPE_LIST', response.data.slide_type || [])
        },
        async getList({ commit }) {
            const response = await apollo.query({ query: GC_GET_NARRATIVE_LIST })

            commit('SET_NARRATIVE_LIST', response.data.narrative)
        },
        async getByID({ commit }, id) {
            let response = await apollo.query({
                query: GC_GET_NARRATIVE_BY_ID,
                variables: { id }
            })

            if (!response.data.narrative_by_pk) {
                return
            }

            commit('SET_NARRATIVE', response.data.narrative_by_pk)
        },
        async save(context, data) {
            let response = null
            let result = {}

            let narrative = {
                id: data.id,
                identifier: data.identifier,
                title: data.title,
                narrative_type_id: data.narrative_type_id,
            }

            // Update or add the narrative
            if (data.id) {
                // Set id
                let variables = {
                    id: data.id,
                    narrative
                }

                // Format condition for deleted slides
                const existingSlides = data.slides.filter((slide) => {
                    return (slide.id ? true : false)
                })

                variables.slide_ids = existingSlides.map((slide) => slide.id)

                // List all slide metas (existing or new) and create condition expression to delete old ones
                const slideMetasData = existingSlides.reduce((data, slide) => {
                    if (!slide.metas)
                        return data

                    // Add new condition rules (to delete existing meta that are no longer in the current list)
                    data.deleteCondition._or.push({
                        slide_id: { _eq: slide.id },
                        id: {
                            _nin: slide.metas.reduce((ids, meta) => {
                                if (meta.id) {
                                    ids.push(meta.id)
                                }

                                return ids
                            }, [])
                        },
                    })

                    // List all meta in existing slide (to be able to insert new ones and update existing ones)
                    slide.metas.forEach((meta) => {
                        meta.slide_id = slide.id

                        data.list.push(meta)
                    })

                    return data
                }, {
                    deleteCondition: { _or: [] },
                    list: [],
                })

                variables.delete_slide_metas_condition = slideMetasData.deleteCondition
                variables.slide_metas = slideMetasData.list

                // Format slides list for upsert
                variables.slides = data.slides.map((slide) => {
                    if (slide.id) {
                        // Remove metas for existing ones (upsert)
                        delete slide.metas
                    } else {
                        // Add narative id and format meta for new ones (insert)
                        slide.narrative_id = data.id
                        slide.metas = { data: slide.metas }
                    }

                    return slide
                })

                // Update remote data
                response = await apollo.mutate({
                    mutation: GC_UPDATE_NARRATIVE_BY_ID,
                    variables
                })

                result.success = true
            } else {
                // Format slides for insert
                narrative.slides = { 
                    data: data.slides.map(slide => {
                        // Format metas for insert
                        slide.metas = { data: slide.metas }

                        return slide
                    }) 
                }

                // Add remote data
                response = await apollo.mutate({
                    mutation: GC_ADD_NARRATIVE_ONE,
                    variables: {
                        narrative
                    }
                })

                result.id = response.data.insert_narrative_one.id
            }

            return result
        },
        async delete(context, id){
            const response = await apollo.mutate({
                mutation: GC_DELETE_NARRATIVE_BY_ID,
                variables: { id }
            })

            return response.data.delete_narrative_by_pk
        },
    }
}