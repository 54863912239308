import {
    GC_GET_LOG_TYPES,
    GC_CHECK_GROUP_ANALYTICS_DATA,
    GC_GET_USERS_ANALYTICS_DATA,
    GC_INSERT_USER_LOGS,
    GC_INSERT_CONTENT_LOG,
    GC_CLEAR_USER_LOGS,
    GC_GET_COURSES_TRACKING_DATA,
    GC_GET_GROUPS_TRACKING_DATA,
    GC_GET_USERS_TRACKING_DATA,
    GC_GET_CUSTOMER_GROUPS_TRACKING_DATA,
    GC_GET_CUSTOMER_USERS_TRACKING_DATA,
    GC_GET_GROUP_USERS_TRACKING_DATA,
    GC_GET_USER_TRACKING_DATA,
} from '@/graphql/logs'
import apollo from '@/apolloClient'

const expirationDelay = (1000 * 60 * 60 * 8)

function hasAnalyticsExpired(lastUpdateDate) {
    if (!lastUpdateDate) {
        return true
    }

    const updateTimestamp = (new Date(lastUpdateDate)).getTime()
    const expirationTimestamp = (Date.now() - expirationDelay)

    return (updateTimestamp < expirationTimestamp)
}

export default {
    namespaced: true,
    state: {
        scenarios: {},
        revisions: {},
        gameNamesByID: {},
        months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
        'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        totalUsers: 0,
        logTypes: null,
        journeys: null,
    },
    mutations: {
        SET_LOG_TYPES(state, logTypes){
            state.logTypes = logTypes.reduce((dict, logType) => {
                dict[logType.slug] = logType.id

                return dict
            }, {})
        },
        SET_JOURNEYS_CACHE(state, journeys) {
            state.journeys = journeys
        }
    },
    actions: {
        async updateGroupAnalytics({ state, commit, rootState, dispatch }, { id }) {
            // Check if we really need to update group analytics (ie. their is no logs or they are expired)
            const response = await apollo.query({
                query: GC_CHECK_GROUP_ANALYTICS_DATA,
                variables: { id },
            })

            if (response?.data?.user_log?.[0]?.updated_at && !hasAnalyticsExpired(response.data.user_log[0].updated_at)) {
                return null
            }

            if (!state.journeys) {
                const journeys = await dispatch('Journey/getList', {}, { root: true })
                commit('SET_JOURNEYS_CACHE', journeys)
            }

            const journey = state.journeys.find((journey) => journey.group?.id === id)

            if (!journey) {
                return null
            }

            const contactAnalytics = await dispatch('Journey/getContactAnalytics', { id: journey.id }, { root: true })

            if (!contactAnalytics || contactAnalytics.length <= 0) {
                return null
            }

            if (!state.logTypes){
                await dispatch('GetLogTypes')
            }

            const userPerEmail = rootState.Utils.userList.reduce((dict, user) => {
                dict[user.email.trim().toLowerCase()] = user
                return dict
            }, {})

            // Create user logs updates for this group
            const userLogs = []
            const data_key = id
            const log_type_id = state.logTypes.analytics

            for (let i = 0; i < contactAnalytics.length; i += 1) {
                const contactAnalytic = contactAnalytics[i]
                const user = userPerEmail[contactAnalytic.email.trim().toLowerCase()]

                if (!user) {
                    continue
                }

                const data = {
                    journey_steps_count: contactAnalytic.journey_steps_count,
                    sent_steps_count: contactAnalytic.sent_steps_count,
                    open_rate: contactAnalytic.open_rate,
                    click_rate: contactAnalytic.click_rate,
                    completion_rate: contactAnalytic.completion_rate,
                    mcq_avg_rate: contactAnalytic.mcq_avg_rate,
                    time_spent_in_seconds: contactAnalytic.time_spent_in_seconds,
                }

                userLogs.push({
                    data_key,
                    data,
                    log_type_id,
                    user_id: user.id,
                })
            }

            // Clear previous user logs
            await apollo.mutate({
                mutation: GC_CLEAR_USER_LOGS,
                variables: {
                    data_key,
                    log_type_id,
                }
            })

            // Save user logs
            await apollo.mutate({
                mutation: GC_INSERT_USER_LOGS,
                variables: {
                    logs: userLogs,
                }
            })

            return userLogs
        },
        async GetUsersAnalyticsData() {
            const response = await apollo.query({
                query: GC_GET_USERS_ANALYTICS_DATA,
            })

            return response.data.user_log
        },
        async GetLogTypes({commit}){
            const response = await apollo.query({
                query: GC_GET_LOG_TYPES
            })
            commit('SET_LOG_TYPES', response.data.log_type);
            return response;
        },
        async ContentLog({state, dispatch, rootState}, data) {
            if (!data.id || !data.action)
                return

            if (!state.logTypes){
                await dispatch('GetLogTypes')
            }

            let obj = {
                log_type_id: state.logTypes[data.action],
                data_key: data.id,
                user_id: rootState['Auth'].userInfo.id
            }

            await apollo.mutate({
                mutation:GC_INSERT_CONTENT_LOG,
                variables: {
                    obj
                }
            })
        },
        async GetCoursesTrackingData() {
            const response = await apollo.query({
                query: GC_GET_COURSES_TRACKING_DATA
            })

            return response.data.course
        },
        async GetGroupsTrackingData() {
            const response = await apollo.query({
                query: GC_GET_GROUPS_TRACKING_DATA,
            })

            return {
                total: response.data.total.aggregate.count,
                opened: response.data.opened.aggregate.count,
            }
        },
        async GetUsersTrackingData({ dispatch }, { roles }) {
            const response = await apollo.query({
                query: GC_GET_USERS_TRACKING_DATA,
                variables: {
                    roles
                }
            })

            const users = response.data.user

            // Check if group analytics are missing or expired
            const analyticsById = response.data.user.reduce((dict, user) => {
                if (!user.user_logs) {
                    return dict
                }

                user.user_logs.forEach((log) => {
                    if (!dict[log.data_key]) {
                        dict[log.data_key] = []
                    }

                    dict[log.data_key].push(log)
                })

                return dict
            }, {})

            for (let i = 0; i < response.data.group.length; i += 1) {
                const group = response.data.group[i]

                if (group.users_aggregate.aggregate.count <= 0) {
                    continue
                }

                const analytics = analyticsById[group.id]?.[0]

                if (analytics && !hasAnalyticsExpired(analytics.updated_at)) {
                    continue
                }

                // Update/Create analytics data
                const groupAnalytics = await dispatch('updateGroupAnalytics', { id: group.id })
                
                // Update user logs if needed
                if (groupAnalytics) {
                    const groupAnalyticsByUserId = groupAnalytics.reduce((dict, analytics) => {
                        dict[analytics.user_id] = analytics
                        return dict
                    }, {})

                    users.forEach((user) => {
                        if (groupAnalyticsByUserId[user.id]) {
                            user.user_logs = user.user_logs.filter(log => log.data_key !== group.id)
                            user.user_logs.push(groupAnalyticsByUserId[user.id])
                        }
                    })
                }
            }

            return users
        },
        async GetCustomerGroupsTrackingData(context, { id }) {
            const response = await apollo.query({
                query: GC_GET_CUSTOMER_GROUPS_TRACKING_DATA,
                variables: {
                    id,
                }
            })

            return {
                list: response.data.customer_by_pk.list,
                total: response.data.customer_by_pk.total.aggregate.count,
                opened: response.data.customer_by_pk.opened.aggregate.count,
            }
        },
        async GetCustomerUsersTrackingData({ dispatch }, { id, roles }) {
            const response = await apollo.query({
                query: GC_GET_CUSTOMER_USERS_TRACKING_DATA,
                variables: {
                    id,
                    roles,
                }
            })

            const groupsById = response.data.customer_by_pk.groups.reduce((dict, group) => {
                dict[group.id] = group
                return dict
            }, {})

            // Flatten/de-duplicate groups users
            const { users } = response.data.customer_by_pk.groups.reduce((dict, group) => {
                group.users.forEach(groupUser => {
                    const user = groupUser.user

                    if (!dict.existingIds[user.id]) {
                        // Filters logs to remove logs from groups outside of the current customer
                        user.user_logs = user.user_logs.filter((log) => {
                            return !!groupsById[log.data_key]
                        })

                        dict.users.push(user)
                    }

                    dict.existingIds[user.id] = true
                })

                return dict
            }, {
                users: [],
                existingIds: {},
            })

            for (let i = 0; i < response.data.customer_by_pk.groups.length; i += 1) {
                const group = response.data.customer_by_pk.groups[i]

                if (group.users.length <= 0) {
                    continue
                }

                // Check if group analytics are missing or expired
                const firstUserWithAnalytics = group.users.find((groupUser) => {
                    if (groupUser.user.user_logs.length <= 0) {
                        return false
                    }

                    return !!groupUser.user.user_logs.find((log) => log.data_key === group.id)
                })

                const firstAnalytics = firstUserWithAnalytics?.user.user_logs.find((log) => log.data_key === group.id)
                
                if (!firstAnalytics || hasAnalyticsExpired(firstAnalytics.updated_at)) {
                    // Update/Create analytics data
                    const groupAnalytics = await dispatch('updateGroupAnalytics', { id: group.id })

                    // Update user logs if needed
                    if (groupAnalytics) {
                        const groupAnalyticsByUserId = groupAnalytics.reduce((dict, analytics) => {
                            dict[analytics.user_id] = analytics
                            return dict
                        }, {})

                        users.forEach((user) => {
                            if (groupAnalyticsByUserId[user.id]) {
                                user.user_logs = user.user_logs.filter(log => log.data_key !== group.id)
                                user.user_logs.push(groupAnalyticsByUserId[user.id])
                            }
                        })
                    }
                }
            }

            return users
        },
        async GetGroupUsersTrackingData({ dispatch }, { id, roles }) {
            const response = await apollo.query({
                query: GC_GET_GROUP_USERS_TRACKING_DATA,
                variables: {
                    id,
                    roles,
                }
            })

            const users = response.data.group_by_pk.users.map(groupUser => groupUser.user)

            if (users.length <= 0) {
                return users
            }

            // Check if group analytics are missing or expired
            const firstUserWithAnalytics = users.find((user) => user.user_logs.length > 0)

            if (firstUserWithAnalytics && !hasAnalyticsExpired(firstUserWithAnalytics.user_logs[0].updated_at)) {
                return users
            }

            // Update/Create analytics data
            const groupAnalytics = await dispatch('updateGroupAnalytics', { id })

            if (!groupAnalytics) {
                return users
            }

            const groupAnalyticsByUserId = groupAnalytics.reduce((dict, analytics) => {
                dict[analytics.user_id] = analytics
                return dict
            }, {})

            return users.map((user) => {
                if (groupAnalyticsByUserId[user.id]) {
                    user.user_logs = [groupAnalyticsByUserId[user.id]]
                }

                return user
            })
        },
        async GetUserTrackingData({ dispatch }, { id, groups }) {
            if (!id || !groups || groups.length <= 0) {
                return {}
            }

            // Get user tracking data
            const response = await apollo.query({
                query: GC_GET_USER_TRACKING_DATA,
                variables: {
                    id,
                }
            })

            const user = response.data.user_by_pk

            if (!user) {
                return {}
            }

            // Get existing data for each groups
            const userGroupsAnalytics = user.user_logs.reduce((dict, log) => {
                dict[log.data_key] = log
                return dict
            }, {})

            // Check if user groups analytics are missing or expired
            for (let i = 0; i < groups.length; i += 1) {
                const groupId = groups[i].id
                const analytics = userGroupsAnalytics[groupId]

                if (analytics && !hasAnalyticsExpired(analytics.updated_at)) {
                    continue
                }

                // Update/Create analytics data
                const groupAnalytics = await dispatch('updateGroupAnalytics', { id: groupId })
                
                const userAnalytics = groupAnalytics?.find((analytics) => analytics.user_id === id)

                if (userAnalytics) {
                    userGroupsAnalytics[groupId] = userAnalytics
                }
            }

            return { byGroup: userGroupsAnalytics }
        },
    },
}