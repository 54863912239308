import gql from 'graphql-tag'

const GC_GET_NARRATIVE_TYPE_LIST = gql`
query GetNarrativeTypeList {
    narrative_type {
      id
      name
      description
      slug
    }
  }
`

const GC_GET_SLIDE_TYPE_LIST = gql`
query GetSlideTypeList {
    slide_type {
      id
      name
      description
      slug
    }
  }
`

const GC_GET_NARRATIVE_LIST = gql`
query GetNarrativeList {
    narrative {
      id
      identifier
      title
      narrative_type_id
    }
  }
`

const GC_GET_NARRATIVE_BY_ID = gql`
query NarrativeByID($id: uuid!) {
  narrative_by_pk(id: $id) {
    id
    identifier
    title
    narrative_type_id
    slides {
      id
      title
      text
      order
      slide_type_id
      narrative_id
      metas {
        id
        value
        meta_type_id
        slide_id
      }
    }
  }
}
`

const GC_ADD_NARRATIVE_ONE = gql`
mutation AddNarrative($narrative: narrative_insert_input!) {
  insert_narrative_one(object: $narrative) {
    id
  }
}
`

/*
  1) Update narrative base data
  2) Delete slides that are no longer associated with this narrative
  3) Insert newly associated slides or update existing ones
  4) Delete metas that are no longer associated with this their slide
  5) Insert newly associated slide metas or update existing ones
*/
const GC_UPDATE_NARRATIVE_BY_ID = gql`
mutation UpdateNarrative(
    $id: uuid!,
    $narrative: narrative_set_input!,
    $slide_ids: [uuid!]!,
    $slides: [slide_insert_input!]!,
		$delete_slide_metas_condition: slide_meta_bool_exp!,
    $slide_metas: [slide_meta_insert_input!]!
	) {
  ## Update narrative base data
  update_narrative_by_pk(pk_columns: { id: $id }, _set: $narrative) {
    id
  }

  ## Delete old slides
  delete_slide(where: {
    id: {_nin: $slide_ids},
    narrative_id: {_eq: $id}
  }) {
    affected_rows
  }

  ## Insert new slides or update base data for existing ones
  insert_slide(
    objects: $slides,
    on_conflict: {
      constraint: slide_pkey,
      update_columns: [title, text, order, slide_type_id]
    }
  ) {
    affected_rows
  }

  ## Delete old slides meta associations
  delete_slide_meta(where: $delete_slide_metas_condition) {
    affected_rows
  }

  ## Add new slide meta associations or update base data for existing ones
  insert_slide_meta(
    objects: $slide_metas,
    on_conflict: {
      constraint: slide_meta_pkey,
      update_columns: [value]
    }
  ) {
    affected_rows
  }
}
`

const GC_DELETE_NARRATIVE_BY_ID = gql`
mutation DeleteNarrative($id: uuid!) {
  delete_narrative_by_pk(id: $id) {
    id
    identifier
    title
  }
}
`

export {
  GC_GET_NARRATIVE_TYPE_LIST,
  GC_GET_NARRATIVE_LIST,
  GC_GET_NARRATIVE_BY_ID,
  GC_ADD_NARRATIVE_ONE,
  GC_UPDATE_NARRATIVE_BY_ID,
  GC_DELETE_NARRATIVE_BY_ID,
  GC_GET_SLIDE_TYPE_LIST
}