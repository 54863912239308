<template>
  <div class="mt-4">
    <div class="inline-block w-1/3 mb-4">
      <label v-if="!isReadOnly">Ajouter un utilisateur</label>

      <v-select
      v-if="!isReadOnly"
      :options="internsList"
      :filterable="true"
      label="name"
      placeholder="Rechercher un utilisateur..."
      v-model="selectedUser"
      @input="onUserSelect($event)"
      />
    </div>

    <div class="inline-block w-2/3 text-right px-4">
      <span>{{ this.promotion.users.length }}</span>
      <span> utilisateurs</span>
    </div>

    <div class="border-line-left border rounded p-4">
      <table class="group-interns w-full">
        <tr>
          <th>Nom</th>
          <th>Prénom</th>
          <th>Email</th>
          <th>Taux de complétude</th>
          <th>Temps passé</th>
          <th>Taux d'ouverture</th>
          <th>Taux de clic</th>
          <th v-if="!isReadOnly"></th>
        </tr>

        <tr
          v-for="intern in promotion.users"
          :key="intern.id"
          class="cursor-pointer"
          @click="onInternClick(intern)"
        >
          <template v-if="interns[intern.id]">
            <td>{{interns[intern.id].first_name}}</td>
            <td>{{interns[intern.id].name}}</td>
            <td>{{interns[intern.id].email}}</td>
            <td>{{interns[intern.id].completionRate}}</td>
            <td>{{interns[intern.id].timeSpent}}</td>
            <td>{{interns[intern.id].openRate}}</td>
            <td>{{interns[intern.id].clickRate}}</td>
            <td
              v-if="!isReadOnly"
              class="flex justify-center text-xl font-bold items-center cursor-pointer col-span-1"
              @click.stop="onRemoveInternClick(intern)"
            >
              <div class="border border-gray-light rounded-full text-center w-8 h-8">-</div>
            </td>
          </template>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

import { formatPercentage, formatTime } from '@/utils/utils'

export default {
  props: {
    promotion: {
      type: Object,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedUser: null,
      internsExtraData: null,
    }
  },
  computed: {
    ...mapState('Utils', ['userList', 'metaTypeList']),
    internsList() {
      if (!this.userList)
        return []

      return this.userList.filter(user => {
        return !this.promotion.users.find(intern => intern.id === user.id)
      }).map((user) => {
        return {
          id: user.id,
          name: [user.first_name, user.name].filter(str => str || false).join(' ') + ' - ' + user.email,
        }
      })
    },
    interns() {
      if (!this.userList || !this.metaTypeList)
        return {}

      return this.userList.reduce((dict, user) => {
        const extraData = this.internsExtraData?.[user.id]

        user.completionRate = '-'
        user.timeSpent = '-'
        user.openRate = '-'
        user.clickRate = '-'

        if (extraData) {
          const trackginData = extraData?.user_logs[0]?.data
          
          if (trackginData) {
            user.completionRate = formatPercentage(trackginData.completion_rate)
            user.openRate = formatPercentage(trackginData.open_rate)
            user.clickRate = formatPercentage(trackginData.click_rate)

            const timeSpentInSeconds = parseFloat(trackginData.time_spent_in_seconds)
            
            if (!isNaN(timeSpentInSeconds)) {
              user.timeSpent = formatTime(timeSpentInSeconds * 1000)
            }
          }
        }

        dict[user.id] = user

        return dict
      }, {})
    },
  },
  watch: {
    'promotion.users': {
      async handler() {
        if (!this.promotion.id) {
          this.internsExtraData = {}
          return
        }

        // Load users extra data (stats mostly)
        const internIds = this.promotion.users.map((user) => user.id)
        const userExtraData = await this.$store.dispatch(
          'Promotion/getInternsExtraData',
          {
            id: this.promotion.id,
            internIds,
          }
        )

        this.internsExtraData = userExtraData.reduce((dict, user) => {
          dict[user.id] = user
          return dict
        }, {})
      },
      immediate: true,
    },
  },
  methods: {
    onUserSelect(intern) {
      this.$emit('add-intern', intern)

      this.$nextTick(() => this.selectedUser = null)
    },
    onInternClick(intern) {
      const route = {
        name: 'user-edit',
        params: {
          itemID: intern.id,
        },
      }

      if (event.metaKey || event.ctrlKey){
        let routeData = this.$router.resolve(route)
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push(route)
      }
    },
    onRemoveInternClick(intern) {
      this.$emit('remove-intern', intern)
    }
  },
};
</script>

<style lang="scss">
  .group-interns {
    @apply border-separate;

    tr {
      &:hover td {
        @apply border-gray-lighter;
      }
    }

    td {
      @apply py-1 border-t-1 border-b-1 border-white;
    }
  }
</style>
