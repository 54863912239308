import gql from 'graphql-tag'

const GC_GET_PROMOTION_TYPE_LIST = gql`
query GetPromotionTypeList {
		group_type {
			id
			name
			description
			slug
		}
	}
`

const GC_GET_PROMOTION_LIST = gql`
query GetPromotionList {
		group {
			id
			identifier
			name
			group_type_id
			start_date
			end_date
			disabled
			awkn_journey_id
			customer_id
			customer {
				name
			}
			users_aggregate {
				aggregate {
					count
				}
			}
		}
	}
`

const GC_GET_PROMOTION_BY_ID = gql`
query PromotionByID($id: uuid!) {
	group_by_pk(id: $id) {
		id
		identifier
		name
		start_date
		end_date
		course_id
		customer_id
		disabled
		awkn_journey_id
		promotion_type_id: group_type_id
		users {
			user_id
		}
	}
}
`

const GC_ADD_PROMOTION_ONE = gql`
mutation AddPromotion(
  $identifier: String!,
  $name: String!,
  $start_date: date!,
  $end_date: date!,
  $course_id: uuid!,
  $awkn_journey_id: Int!,
  $customer_id: uuid!,
  $disabled: Boolean,
  $promotion_type_id: uuid!,
  $sequences: [group_sequence_insert_input!]!,
  $users: [group_user_insert_input!]!
) {
	insert_group_one(object: {
		identifier: $identifier,
		name: $name,
		start_date: $start_date,
		end_date: $end_date,
		course_id: $course_id,
		customer_id: $customer_id,
		disabled: $disabled,
		group_type_id: $promotion_type_id,
		awkn_journey_id: $awkn_journey_id
		sequences: {
			data: $sequences
		},
		users: {
			data: $users
		}
	}) {
		id
	}
}
`

/*
  1) Update promotion base data
  2) TODO : Delete sequences that are no longer associated with this promotion
  3) TODO : Insert newly associated sequences or update existing ones
  4) Delete users that are no longer associated with this promotion
  5) Insert newly associated users
*/
const GC_UPDATE_PROMOTION_BY_ID = gql`
mutation UpdatePromotion(
		$id: uuid!,
		$identifier: String!,
		$name: String!,
		$start_date: date!,
		$end_date: date!,
		$course_id: uuid!,
		$awkn_journey_id: Int!,
		$customer_id: uuid!,
		$disabled: Boolean,
		$promotion_type_id: uuid!,
		### $sequences_ids: [uuid!]!,
		$sequences: [group_sequence_insert_input!]!,
		$users_ids: [uuid!]!,
		$users: [group_user_insert_input!]!,
	) {
	update_group_by_pk(pk_columns: { id: $id },
	_set: {
		identifier: $identifier,
		name: $name,
		start_date: $start_date,
		end_date: $end_date,
		course_id: $course_id,
		customer_id: $customer_id,
		awkn_journey_id: $awkn_journey_id,
		disabled: $disabled,
		group_type_id: $promotion_type_id,
	}) {
		id
		identifier
		name
		start_date
		end_date
		course_id
		customer_id
		disabled
		awkn_journey_id
		users {
			user_id
		}
	}

	delete_group_sequence(where: {
		### sequence_id: {_nin: $sequences_ids},
		group_id: {_eq: $id}
	}) {
		affected_rows
	}
	insert_group_sequence(
		objects: $sequences,
		on_conflict: {
			constraint: group_sequence_group_id_sequence_id_user_id_key,
			update_columns: [start_date, end_date]
		}
	) {
		affected_rows
	}

	delete_group_user(where: {
		user_id: {_nin: $users_ids},
		group_id: {_eq: $id}
	}) {
		affected_rows

    returning {
			user {
				id
				last_name
				first_name
				email
			}
		}
	}
	insert_group_user(
		objects: $users,
		on_conflict: {
			constraint: group_user_pkey,
			update_columns: []
		}
	) {
		affected_rows

		returning {
			user {
				id
				last_name
				first_name
				email
			}
		}
	}
}
`

const GC_DELETE_PROMOTION_BY_ID = gql`
mutation DeletePromotion($id: uuid!) {
	delete_group_by_pk(id: $id) {
		id
		identifier
		name
	}
}
`

const GC_GET_INTERNS_EXTRA_DATA = gql`
query GetInternsExtraData($id: uuid!, $internIds: [uuid!]!) {
	user(where: {id: {_in: $internIds}}) {
		id
		user_logs(where: {type: {slug: {_eq: "analytics"}}, data_key: {_eq: $id}}) {
			data
			updated_at
		}
	}
}
`

export {
  GC_GET_PROMOTION_TYPE_LIST,
  GC_GET_PROMOTION_LIST,
  GC_GET_PROMOTION_BY_ID,
  GC_ADD_PROMOTION_ONE,
  GC_UPDATE_PROMOTION_BY_ID,
  GC_DELETE_PROMOTION_BY_ID,
  GC_GET_INTERNS_EXTRA_DATA,
}