export function computeTrackingDataForList(list, dataComputeCallback) {
	const dataKeys = Object.keys(dataComputeCallback)

	return list.reduce((result, usersAnalyticsData) => {
		dataKeys.forEach((key) => {
			result[key] = dataComputeCallback[key](usersAnalyticsData)
		})

		return result
	}, {})
}

/* Computing callback factories */

export function countMatch(filter) {
	let count = 0

	return (item) => {
		if (filter(item)) {
			count += 1
		}

		return count
	}
}

export function averageField(field, subfield = null, valueFilter = null) {
	const isValid = (valueFilter || ((value) => (value || value === 0)))

	let sum = 0
	let count = 0

	// Create getValue function
	let getValue = (item) => item[field]

	if (subfield) {
		getValue = (item) => item[field][subfield]
	}

	return (item) => {
		// Compute new sum and count
		const value = getValue(item)

		if (isValid(value)) {
			sum += value
			count += 1
		}

		if (count <= 0)
			return 0.0

		return (sum / count)
	}
}

export function maxField(field, subfield = null, valueFilter = null) {
	const isValid = (valueFilter || ((value) => (value || value === 0)))

	let max = 0

	// Create getValue function
	let getValue = (item) => item[field]

	if (subfield) {
		getValue = (item) => item[field][subfield]
	}

	return (item) => {
		// Compute max value
		const value = getValue(item)

		if (isValid(value) && value > max) {
			max = value
		}

		return max
	}
}