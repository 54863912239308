<template>
  <div>
    <List
      v-if="!isLoading"
      listing="customer"
      :fields="fields"
      search-text="Rechercher un client"
      :pageSize="pageSize"
      edit-route-name="customer-edit"
    >
      <template v-slot:buttons>
        <div class="md:flex flex-row justify-around hidden md:w-1/3">
          <button
            type="button"
            class="twn-button whitespace-no-wrap overflow-hidden text-xs mr-2"
            @click="onAddCustomerClick"
          >
            Ajouter un nouveau client
          </button>
        </div>
      </template>
    </List>
  </div>
</template>

<script>
  import List from "@/components/List";
  import { mapActions } from "vuex";

  export default {
    components: {
      List,
    },
    props: {
      pageSize:{
        type: Number,
        default: 30,
        required: false
      }
    },
    data: () => ({
      isLoading: false,
      fields: [
        {
          key: "identifier",
          label: "Identifiant",
          sortable: true,
        },
        {
          key: "name",
          label: "Nom",
          sortable: true,
        },
        {
          key: 'groups_aggregate',
          label: 'Nb Promotions',
          sortable: true,
          db: `groups_aggregate {
            aggregate {
              count
            }
          }`,
          nested: 'count'
        },
        {
          key: 'groups.users_aggregate',
          label: 'Stagiaires',
          sortable: true,
          db: `groups {
            users_aggregate {
              aggregate {
                count
              }
            }
          }`,
          formatter: (value, key, item) => {
            if (!item.groups)
              return 0

            return item.groups.reduce((count, group) => (count + group.users_aggregate.aggregate.count), 0)
          }
        },
        {
          label: 'Actifs',
          sortable: true,
          key: 'activeGroups',
          db: `activeGroups: groups(where: { disabled: {_eq: false}}) {
            users_aggregate(where: {user: {disabled: {_eq: false}, cognito_confirmed: {_eq: true}}}) {
              aggregate {
                count
              }
            }
          }`,
          formatter: (value, key, item) => {
            if (!item.activeGroups)
              return 0

            return item.activeGroups.reduce((count, group) => (count + group.users_aggregate.aggregate.count), 0)
          }
        },
      ]
    }),
    methods: {
      ...mapActions("EditItem", ["clearCurrentID"]),
      onAddCustomerClick() {
        this.clearCurrentID();
        this.$router.push({ name: "customer-create" });
      },
    },
  };
</script>