<template>
    <MainDashboard />
</template>

<script>
    import MainDashboard from '@/components/dashboard/Main'

    export default {
        name: 'Dashboard',
        components: {
            MainDashboard,
        },
    }
</script>
