<template>
	<div class="mt-4">
		<div class="text-xl mb-4">
			<span>{{ this.customer.groups.length }}</span>
			<span> promotions attachés</span>
		</div>

		<div class="inline-block w-1/3 mb-8">
			<label>Attacher une nouvelle promotion</label>
			<v-select
			placeholder="Rechercher une promotion..."
			:getOptionLabel="getGroupLabel"
			:options="groupList"
			:reduce="customer => customer.id"
			:disabled="loading"
			@input="onGroupSelect($event)"
			v-model="selectedGroup"
			/>
		</div>

		<div class="border-line-left border rounded p-4" :class="{ 'opacity-60': loading }">
			<div class="flex p-2 my-2 font-principal-medium">
				<p class="flex-1">Identifiant</p>
				<p class="flex-1">Nom</p>
				<p class="flex-1">Stagiaires</p>
				<p class="flex-1">Date de début</p>
				<p class="flex-1">Statut</p>
				<p class="flex-1">Capsules envoyées</p>

				<div
				class="flex justify-center text-xl font-bold items-center cursor-pointer col-span-1"
				>
					<div class="border border-white rounded-full text-center w-8 h-8"></div>
				</div>
			</div>

			<div
			v-for="group in customer.groups"
			:key="group.id"
			class="flex p-2 my-2 border-b border-gray-light"
			>
				<template v-if="groupData[group.id]">
					<p class="flex-1">{{groupData[group.id].identifier}}</p>
					<p class="flex-1">{{groupData[group.id].name}}</p>
					<p class="flex-1">{{groupData[group.id].users_aggregate.aggregate.count}}</p>
					<p class="flex-1">{{groupData[group.id].start_date }}</p>
					<p class="flex-1">{{getGroupStatus(group.id)}}</p>
					<p class="flex-1">
						{{ (groupData[group.id].analytics && groupData[group.id].analytics.sentSteps ? groupData[group.id].analytics.sentSteps + ' / ' + groupData[group.id].analytics.stepsCount : '-') }}
					</p>
				</template>

				<div
				class="flex justify-center text-xl font-bold items-center cursor-pointer col-span-1"
				@click="onRemoveFromGroupClick(group.id)"
				>
					<div class="border border-gray-light rounded-full text-center w-8 h-8">-</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import { mapState } from 'vuex'

	export default {
		props: {
			customer: {
				type: Object,
				required: true
			},
			loading: {
				type: Boolean,
				required: true
			},
			trackingData: {
				type: Object,
				default: null,
			},
		},
		data() {
			return {
				selectedGroup: null
			}
		},
		computed: {
			...mapState('Promotion', {
				promotionList: 'list'
			}),
			groupList() {
				return this.promotionList.filter((group) => {
					return !group.customer && !this.customer.groups.some((attachedGroup) => (group.id == attachedGroup.id))
				})
			},
			groupData() {
				if (!this.promotionList)
					return {}

				return this.promotionList.reduce((dict, group) => {
					if (this.trackingData) {
						group.analytics = this.trackingData.groupsAnalytics[group.id]
					}

					dict[group.id] = group

					return dict
				}, {})
			},
		},
		methods: {
			getGroupLabel(group) {
				if (!group)
					return 'Promotion supprimé'

				return [group.identifier, group.name].filter(str => str || false).join(' ')
			},
			onGroupSelect(groupID) {
				this.$emit('add-to-group', groupID)

				this.$nextTick(() => this.selectedGroup = null)
			},
			onRemoveFromGroupClick(groupID) {
				this.$emit('remove-from-group', groupID)
			},
			getGroupStatus(groupID) {
				const group = this.groupData[groupID]

				if (group.disabled)
					return 'Suspendue'

				const now = new Date()
				const start = new Date(group.start_date)
				const end = new Date(group.end_date)

				return (now < start ? 'À venir' : (now > end ? 'Passé' : 'En cours'))
			}
		}
	};
</script>