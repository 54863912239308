import axios from 'axios'

import apollo from '@/apolloClient'

import {
    GC_GET_USER_LIST,
    GC_GET_USER_BY_ID,
    GC_ADD_USER_ONE,
    GC_UPDATE_USER_BY_ID,
    GC_DELETE_USER_BY_ID,
} from '@/graphql/user'

const authURL = process.env.VUE_APP_API_URL + '/auth'

export default {
    namespaced: true,
    state: {
        list: [],
        items: {},
    },
    mutations: {
        SET_USER_LIST(state, users) {
            state.list = (users || []);
        },
        SET_USER(state, user) {
            if (!user || !user.id)
                return

            state.items[user.id] = user
        },
    },
    actions: {
        // Return default user data object
        getDefault() {
            return {
                name: '',
                first_name: '',
                email: '',
                role: 'user',
                disabled: false,
                metas: [],
                groups: [],
            }
        },
        async getList({ commit }) {
            const response = await apollo.query({ query: GC_GET_USER_LIST })

            commit('SET_USER_LIST', response.data.user)
        },
        async getByID({ commit }, id) {
            let response = await apollo.query({
                query: GC_GET_USER_BY_ID,
                variables: { id }
            })

            if (!response.data.user_by_pk) {
                return
            }

            commit('SET_USER', response.data.user_by_pk)
        },
        resetPassword(context, email) {
            return axios.post(
                authURL + '/reset',
                {
                    username: email,
                }
            )
        },
        async save(context, data) {
            let response = null
            let result = {}

            // Update or add the user
            if (data.user.id) {
                // Update user group journeys contact
                for (let i = 0; i < data.user.groups.length; i += 1) {
                    const group = data.user.groups[i].group

                    if (group.awkn_journey_id) {
                        await context.dispatch('Journey/updateContact', {
                            id: group.awkn_journey_id,
                            user: data.user,
                        }, { root: true })
                    }
                }

                // Remove email and cognito status for update
                delete data.user.email
                delete data.user.cognito_confirmed

                // Format metas
                data.user.metas = data.user.metas.map((meta) => {
                    meta.user_id = data.user.id

                    return meta
                })

                // Format groups
                data.user.groups = data.user.groups.map((group) => {
                    return {
                        group_id: group.id,
                        user_id: data.user.id
                    }
                })

                // Update remote data
                response = await apollo.mutate({
                    mutation: GC_UPDATE_USER_BY_ID,
                    variables: data.user
                })

                result.success = true
            } else {
                data.user.cognito_id = null

                // Register user if needed
                if (['superadmin', 'customer_manager'].indexOf(data.user.role) > -1) {
                    try {
                        response = await axios.post(
                            authURL + '/adminregister',
                            {
                                email: data.user.email,
                            },
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + context.rootGetters['Auth/userToken']
                                }
                            }
                        )
                    } catch (error) {
                        return {
                            error
                        }
                    }

                    data.user.cognito_id = response.data.message
                }

                // Format metas
                data.user.metas = data.user.metas.map((meta) => {
                    delete meta.id
                    delete meta.user_id

                    return meta
                })

                // Format groups
                data.user.groups = data.user.groups.map((group) => {
                    return {
                        group_id: group.id
                    }
                })

                // Add remote data
                response = await apollo.mutate({
                    mutation: GC_ADD_USER_ONE,
                    variables: data.user
                })

                result.id = response.data.insert_user_one.id
            }

            await context.dispatch('Utils/getUsers', null, { root: true })

            return result
        },
        async delete(context, user) {
            if (!user.id) {

                return {
                    error: true
                }
            }

            let response = null

            try {
                // Disable user group journeys contact
                user.disabled = true

                for (let i = 0; i < user.groups.length; i += 1) {
                    const group = user.groups[i].group

                    if (group.awkn_journey_id) {
                        await context.dispatch('Journey/updateContact', {
                            id: group.awkn_journey_id,
                            user: user,
                        }, { root: true })
                    }
                }

                response = await apollo.mutate({
                    mutation: GC_DELETE_USER_BY_ID,
                    variables: { id: user.id }
                })

                if (user.cognito_id) {

                    // Delete user from auth api
                    response = await axios.post(
                        authURL + '/admindelete',
                        {
                            username: user.cognito_id
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + context.rootGetters['Auth/userToken']
                            }
                        }
                        )
                    }
            } catch (error) {
                return {
                    error
                }
            }

            await context.dispatch('Utils/getUsers', null, { root: true })

            return response.data.delete_users_by_pk
        }
    }
}
