<template>
	<div class="dashboard-customer">
		<!-- Header -->
		<div class="flex items-center justify-between">
			<div class="mb-4">
				<Toggle
				v-if="isSuperAdmin"
				:checked="includeOtherRoles"
				:onInput="() => includeOtherRoles = !includeOtherRoles"></Toggle>
				<span v-if="isSuperAdmin" class="ml-2">Inclure les admins/responsables clients</span>
			</div>
			<!-- <button type="button" class="mb-4 twn-button" @click="ExportReport" v-bind:disabled="isLoading">
				<span v-if="!isLoading">
					Exporter le rapport
				</span>
				<span v-else>
					Chargement...
				</span>
			</button> -->
		</div>

		<!-- Dashboard -->
		<p v-if="isLoading || !trackingData">
			Chargement...
		</p>
		<div v-else>
			<CategoryBlock title="Statistiques du client">
				<!-- Group and user KPIs -->
				<div class="dashboard-customer-kpis mb-8">
					<KPIBlock
						title="Promotions"
						:number="trackingData.groups.total"
					/>
					<KPIBlock
						title="Promotions ouvertes"
						:number="trackingData.groups.opened"
						backgroundColor="bg-dashboard-blue-dark"
					/>
					<KPIBlock
						title="Utilisateurs inscrits"
						:number="trackingData.users.length"
					/>
					<KPIBlock
						title="Utilisateurs actifs"
						:number="trackingData.usersData.activeCount"
						backgroundColor="bg-dashboard-green"
						help="Nombre de stagiaires ayant ouvert au moins une capsule."
					/>
				</div>

				<!-- User open/click rates KPIs -->
				<div class="dashboard-customer-kpis">
					<KPIBlock
						title="Taux d’ouverture"
						:percentage="trackingData.usersData.openRate"
						help="Nombre d'ouvertures uniques divisé par le nombre d'emails envoyés hors relance."
					/>
					<KPIBlock
						title="Taux de clic"
						:percentage="trackingData.usersData.clickRate"
						help="Nombre de clics uniques divisé par le nombre de capsules cliquables envoyées."
					/>
				</div>
			</CategoryBlock>
		</div>
	</div>
</template>

<script>
	import trackingReport from '@/utils/trackingReport'

	import trackingReportTemplate from "@/constants/customerTrackingReportTemplate"

	import Toggle from '@/components/Toggle'

	import CategoryBlock from "./block/Category"
	import KPIBlock from "./block/KPI"

	export default {
		name: 'MainDashboard',
		components: {
			Toggle,
			CategoryBlock,
			KPIBlock,
		},
		props: {
			customer: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				isLoading: false,
				trackingData: null,
				includeOtherRoles: false,
			}
		},
		computed: {
			isSuperAdmin() {
				const userInfo = this.$store.state.Auth.userInfo

				return (userInfo && userInfo.role == 'superadmin')
			},
		},
		watch: {
			customer: {
				async handler(customer) {
					this.isLoading = true

					// Load tracking data
					const payload = { id: customer.id }

					if (this.includeOtherRoles) {
						payload.roles = ['user', 'superadmin', 'customer_manager']
					}

					this.trackingData = await trackingReport.loadData(this.$store, trackingReportTemplate, payload)
					this.$emit('update-data', this.trackingData)

					this.isLoading = false
				},
				immediate: true
			},
			async includeOtherRoles(includeOtherRoles) {
				this.isLoading = true

				// Load tracking data
				const payload = { id: this.customer.id }

				if (includeOtherRoles) {
					payload.roles = ['user', 'superadmin', 'customer_manager']
				}

				this.trackingData = await trackingReport.loadData(this.$store, trackingReportTemplate, payload)
				this.$emit('update-data', this.trackingData)

				this.isLoading = false
			}
		},
		methods: {
			async ExportReport(){
				this.isLoading = true
				
				try {
					const customerName = (this.customer.identifier ? this.customer.identifier : this.customer.name).substring(0, 7).trim().replace(' ', '_')
					await trackingReport.export('Client_' + customerName, this.$store, trackingReportTemplate, null, this.trackingData)
				} catch (err) {
					// console.error(err)
				}

				this.isLoading = false
			}
		}
	}
</script>

<style lang="scss">
	.dashboard-customer {
		@apply max-w-screen-lg mx-auto;

		&-kpis {
			@apply flex flex-wrap justify-between;

			&:first-child {
				@apply mb-8;
			}
		}

		.dashboard-category {
			@apply w-full mb-5;

			.dashboard-kpi {
				@apply flex-1 mx-4;

				&:first-child {
					@apply ml-0;
				}

				&:last-child {
					@apply mr-0;
				}
			}
		}

		&-active-user {
			& > p {
				display: inline-block;
			}
		}

		&-finish-by-job {
			@apply flex;
			padding-left: 100px;

			.dashboard-chart {
				@apply pr-8 mb-3;
			}

			& > div:first-child {
				margin-left: -100px;

				p.ct-label {
					margin-left: 100px;
				}
			}

			p.ct-label {
				@apply text-dashboard-blue-dark;

				font-style: italic;
			}
		}
	}
</style>