<template>
	<div class="narrative" v-if="narrative">
		<!-- Header -->
		<div class="narrative-header">
			<div class="buttons flex flex-row justify-between">
				<router-link class="twn-button text-xs" :to="{ name: 'narrative-list' }">
					Retour
				</router-link>

				<div class="buttons-right">
					<button
						@click="deleteNarrative"
						class="twn-button text-xs danger mr-4"
						type="button"
						:disabled="!itemID || hasPendingStoreRequest"
					>
						Supprimer
					</button>

					<button
						@click="duplicateNarrative"
						class="twn-button text-xs mr-4"
						type="button"
						:disabled="!itemID || hasPendingStoreRequest"
					>
						Dupliquer
					</button>

					<button
						@click="saveNarrative"
						class="twn-button text-xs"
						type="button"
						:disabled="hasPendingStoreRequest"
					>
						Enregistrer
					</button>
				</div>
			</div>
		</div>

		<!-- Narrative common fields -->
		<div class="pb-6 mb-10 mt-12 border-gray-light border-b-1">
			<!-- Identifier and title -->
			<div class="form-group inline-block w-1/3 pr-2">
				<label for="narrative-name" class="uppercase font-principal-medium text-sm">Identifiant</label>
				<b-form-input
					v-model="narrative.identifier"
					type="text"
					id="narrative-name"
					class="w-full"
				></b-form-input>
			</div>

			<!-- Name -->
			<div class="form-group inline-block w-2/3 pl-2">
				<label for="narrative-title" class="uppercase font-principal text-sm"
					>Titre</label
				>
				<b-form-input v-model="narrative.title" type="text" id="narrative-title" class="w-full"></b-form-input>
			</div>
		</div>

		<!-- Slides -->
		<div>
			<Draggable v-model="slides" handle=".handle">
				<!-- Type specific slide editing component -->
				<component
					v-for="(slide, index) in slides"
					:key="slide.id || index"
					:is="slideComponent"
					:slide="slide"
					@update="updateSlide(index, $event)"
					@remove="removeSlide(index)"
				/>
			</Draggable>

			<div
				@click="addSlide"
				class="plus m-auto rounded-full w-12 h-12 flex justify-center items-center cursor-pointer text-xl mt-6"
			>
				+
			</div>
		</div>

		<!-- Modals -->
		<b-modal
			ref="delete-modal-narrative"
			class="bootstrap"
			centered
			hide-footer
			id="delete-modal-narrative"
			hide-header
		>
			<div class="d-block text-center my-6 uppercase font-semibold">
				<h3>Confirmer la suppression</h3>
			</div>

			<div class="flex flex-row justify-evenly items-center">
				<button type="button" class="mt-4 twn-button" @click="$bvModal.hide('delete-modal-narrative')">
					Retour
				</button>
				
				<button type="button" class="mt-4 twn-button danger" @click="confirmDelete">Supprimer</button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	import dispatchStoreRequest from '@/mixins/dispatchStoreRequest'

	import Draggable from 'vuedraggable'

	import NarratorSlide from "@/components/common/narrative/NarratorSlide"

	export default {
		name: 'NarrativeDetail',
		mixins: [ dispatchStoreRequest ],
		components: {
			Draggable
		},
		props: {
			itemID: {
				type: String,
				required: false,
				default: null
			}
		},
		data() {
			return {
				narrative: null
			}
		},
		computed: {
			...mapState('Narratives', {
				types: (state) => {
					if (!state.typeList || state.typeList.length <= 0)
						return {}

					return state.typeList.reduce((dict, type) => {
						dict[type.slug] = type

						return dict
					}, {})
				},
				slideTypes: (state) => {
					if (!state.slideTypeList || state.slideTypeList.length <= 0)
						return {}

					return state.slideTypeList.reduce((dict, type) => {
						dict[type.slug] = type

						return dict
					}, {})
				}
			}),
			slideComponent() {
				if (this.narrative) {
					const type = this.$store.state.Narratives.typeList.find(type => (type.id == this.narrative.narrative_type_id))

					switch (type.slug) {
						case 'narrator':
						return NarratorSlide
					}
				}

				return null
			},
			slides: {
				get() {
					if (!this.narrative)
						return []

					return [...this.narrative.slides]
				},
				set(slides) {
					// Make sure order field is correct
					slides.forEach((slide, index) => {
						slide.order = index
					})

					this.narrative.slides = slides
				}
			}
		},
		watch: {
			itemID: {
				async handler(id) {
					// Load current narrative data, if needed
					if (id) {
						// Get narrative from store
						// todo: handle invalid uuid response
						await this.dispatchStoreRequest('Narratives/getByID', id, true)

						// Create a deep local copy of the store data
						this.narrative = JSON.parse(JSON.stringify(this.$store.state.Narratives.items[id]))

						// todo: common/cleaner system
						document.title = 'Oriente Solutions LMS - ' + [this.narrative.identifier, this.narrative.title].join(' - ')
					}
				},
				immediate: true
			},
			narrative: {
				handler(narrative) {
					// Automaticly add one slide if none provided
					if (narrative && narrative.slides && narrative.slides.length <= 0) {
						this.addSlide()
					}
				},
				immediate: true
			}
		},
		async created() {
			await this.dispatchStoreRequest('Narratives/getTypeList')
			await this.dispatchStoreRequest('Narratives/getSlideTypeList')
			await this.dispatchStoreRequest('Assets/getAssetList')

			if (!this.itemID) {
				// Create a new narrative of the default type
				this.narrative = {
					identifier: '',
					title: 'Nouvelle narration',
					narrative_type_id: this.types['narrator'].id,
					slides: []
				}
			}
		},
		methods: {
			async duplicateNarrative(){

				this.narrative.title = this.narrative.title + ' (copie)'

				// Remove fields used for update format
				delete this.narrative.id

				this.narrative.slides.forEach(slide => {
					delete slide.id
					delete slide.narrative_id

					slide.metas.forEach(meta => {
						delete meta.id
						delete meta.slide_id
					})
				})

				this.$router.push({
					name: 'narrative-edit'
				})
			},
			async saveNarrative() {
				const response = await this.dispatchStoreRequest("Narratives/save", this.narrative)

				this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })

				if (!this.itemID && response.id) {
					this.$router.push({
						name: 'narrative-edit',
						params: {
							itemID: response.id
						}
					})
				}
			},
			deleteNarrative() {
				if (this.itemID) {
					this.$refs["delete-modal-narrative"].show()
				}
			},
			async confirmDelete() {
				await this.dispatchStoreRequest("Narratives/delete", this.narrative.id)

				this.$router.push({ name: 'narrative-list' })
			},
			addSlide() {
				this.narrative.slides.push({
					title: 'Nouveau slide',
					text: '',
					order: this.narrative.slides.length,
					slide_type_id: this.slideTypes['narrator'].id,
					metas: []
				})
			},
			updateSlide(index, slideData) {
				const dataKeys = Object.keys(slideData)

				for (var i = 0; i < dataKeys.length; i++) {
					this.narrative.slides[index][dataKeys[i]] = slideData[dataKeys[i]]
				}
			},
			removeSlide(index) {
				if (this.narrative.slides.length <= 0)
					return

				// Remove slide
				this.narrative.slides.splice(index, 1)
				
				// Make sure order field is correct
				this.narrative.slides.forEach((slide, i) => {
					slide.order = i
				})
			}
		}
	}
</script>

<style lang="scss" scoped></style>