import apollo from '@/apolloClient'

import {
    GC_GET_JOURNEY_LIST,
    GC_GET_JOURNEY_CONTACTS,
    GC_GET_JOURNEY_CONTACT_ANALYTICS,
    GC_UPDATE_JOURNEY,
    GC_DUPLICATE_JOURNEY,
    GC_UPDATE_JOURNEY_CONTACT,
    GC_ADD_JOURNEY_CONTACT,
} from '@/graphql/journey'

export default {
    namespaced: true,
    state: {
        list: [],
    },
    mutations: {
        SET_JOURNEY_LIST(state, journeys) {
            state.list = (journeys || []);
        },
    },
    actions: {
        async getList({ commit, state }) {
            const response = await apollo.query({ query: GC_GET_JOURNEY_LIST })

            commit('SET_JOURNEY_LIST', response.data.journey)

            return state.list
        },
        async getContactAnalytics(context, { id }) {
            try {
                const response = await apollo.query({
                    query: GC_GET_JOURNEY_CONTACT_ANALYTICS,
                    variables: { id }
                })

                return response.data.contact_analytics_by_journey_id
            } catch (err) {
                return null
            }
        },
        async update(context, { id, group_name, active_status }) {
            try {
                const response = await apollo.mutate({
                    mutation: GC_UPDATE_JOURNEY,
                    variables: {
                        id,
                        journey: {
                            group_name,
                            active_status,
                        }
                    }
                })

                return response.data.update_journey_by_pk
            } catch (err) {
                return null
            }
        },
        async updateContacts(context, { id, users }) {
            // Get users data
            if (!context.rootState.Utils.userList || context.rootState.Utils.userList.length <= 0) {
                await context.dispatch('Utils/getUsers', null, { root: true });
            }

            const promotionUsers = users.map((user) => {
                const userData = context.rootState.Utils.userList.find((u) => u.id === user.id)

                return userData
            })

            // Get journey contacts list
            let response = null

            try {
                response = await apollo.query({
                    query: GC_GET_JOURNEY_CONTACTS,
                    variables: { id }
                })
            } catch (err) {
                return
            }

            const contacts = (response?.data?.journey_by_pk?.contacts || [])

            // Disable removed contact in the journey
            const userDict = promotionUsers.reduce((dict, user) => {
                dict[user.email.trim().toLowerCase()] = user
                return dict
            }, {})

            for (let i = 0; i < contacts.length; i += 1) {
                const contact = contacts[i]

                if (!userDict[contact.email.trim().toLowerCase()]) {
                    await apollo.mutate({
                        mutation: GC_UPDATE_JOURNEY_CONTACT,
                        variables: {
                            id,
                            contact_id: contact.id,
                            contact: {
                                blocked_status: 1,
                            },
                        },
                    })
                }
            }

            // Add new contact to the journey or update blocked status for existing one
            const contactDict = contacts.reduce((dict, contact) => {
                dict[contact.email.trim().toLowerCase()] = contact
                return dict
            }, {})

            for (let i = 0; i < promotionUsers.length; i += 1) {
                const user = promotionUsers[i]
                const contact = contactDict[user.email.trim().toLowerCase()]

                if (!contact) {
                    const newContactResponse = await apollo.mutate({
                        mutation: GC_ADD_JOURNEY_CONTACT,
                        variables: {
                            contact: {
                                email: user.email,
                                first_name: user.first_name,
                                last_name: (user.name || user.last_name),
                                journey_id: id,
                            },
                        },
                    })

                    const contactId = newContactResponse?.data?.insert_journey_contact_one.id

                    if (contactId && user.disabled) {
                        await apollo.mutate({
                            mutation: GC_UPDATE_JOURNEY_CONTACT,
                            variables: {
                                id,
                                contact_id: contactId,
                                contact: {
                                    blocked_status: 1,
                                },
                            },
                        })
                    }
                } else if (contact.blocked_status !== (user.disabled ? 1 : 0)) {
                    await apollo.mutate({
                        mutation: GC_UPDATE_JOURNEY_CONTACT,
                        variables: {
                            id,
                            contact_id: contact.id,
                            contact: {
                                blocked_status: (user.disabled ? 1 : 0),
                            },
                        },
                    })
                }
            }
        },
        async updateContact(context, { id, user }) {
            // Get journey contacts list
            let response = null

            try {
                response = await apollo.query({
                    query: GC_GET_JOURNEY_CONTACTS,
                    variables: { id }
                })
            } catch (err) {
                return
            }

            const contacts = (response?.data?.journey_by_pk?.contacts || [])

            // Find user contact data
            const contact = contacts.find((contact) => contact.email.trim().toLowerCase() === user.email.trim().toLowerCase())

            if (!contact) {
                return
            }

            // Check if we really need to update the contact
            const newContactData = {}

            if (contact.email != user.email) {
                newContactData.email = user.email
            }

            if (contact.first_name != user.first_name) {
                newContactData.first_name = user.first_name
            }

            if (contact.last_name != (user.name || user.last_name)) {
                newContactData.last_name = (user.name || user.last_name)
            }

            const blockedStatus = (user.disabled ? 1 : 0)

            if (contact.blocked_status != blockedStatus) {
                newContactData.blocked_status = blockedStatus
            }

            if (Object.keys(newContactData).length <= 0) {
                return
            }

            await apollo.mutate({
                mutation: GC_UPDATE_JOURNEY_CONTACT,
                variables: {
                    id,
                    contact_id: contact.id,
                    contact: newContactData,
                },
            })
        },
        async duplicate(context, { id, group_name, start_date, training_name }){
            const response = await apollo.mutate({
                mutation: GC_DUPLICATE_JOURNEY,
                variables: { id, group_name, start_date, training_name }
            })

            return response.data.duplicate_journey_by_pk
        },
    }
}