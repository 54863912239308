<template>
  <div>
    <cooc-list
      v-if="!isLoading"
      listing="user"
      :fields="fields"
      :filters="filters"
      searchText="Rechercher un utilisateur"
      :with-delete-button="false"
      modify-button-text="Éditer la fiche Utilisateur"
    >
      <template v-slot:buttons>
        <div v-if="!isReadOnly" class="md:flex flex-row justify-around hidden user-buttons w-1/3">
          <button
            @click="addUser"
            type="button"
            class="hidden md:block twn-button whitespace-no-wrap overflow-hidden text-xs mr-2"
          >Ajouter un utilisateur</button>
          <button
            @click="importUserList"
            type="button"
            class="twn-button hidden md:block whitespace-no-wrap overflow-hidden text-xs"
          >Importer des utilisateurs</button>
        </div>
      </template>
      <template v-slot:fab>
        <vue-fab
          class="md:hidden"
          fabItemAnimate="alive"
          fabAliveAnimateBezier="ease-in-out"
          mainBtnColor="#fff"
          size="big"
        >
          <fab-item
            class="twn-fab import"
            @clickItem="importUserList"
            color="#888888"
            :idx="2"
            title="Importer des utilisateurs"
            icon="add"
          />
          <fab-item
            class="twn-fab add"
            @clickItem="addUser"
            color="#DC9799"
            :idx="0.5"
            title="Ajouter un utilisateur"
            icon="add"
          />
        </vue-fab>
      </template>
    </cooc-list>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';

import userFieldsValues from "@/constants/userFieldsValues"

import List from '@/components/List'

const now = Date.now()

export default {
  name: 'UserList',
  data() {
    return {
      isLoading: true,
      level1: null,
      level2: null,
      fields: [
        {
          key: 'last_name',
          label: 'Nom',
          sortable: true
        },
        {
          key: 'first_name',
          label: 'Prénom',
          sortable: true,
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
        },
        {
          key: 'role',
          sortable: true,
          formatter: (value) => {
            switch (value) {
              case 'user':
                return "Utilisateur"

              case 'customer_manager':
                return "Responsable client"

              case 'superadmin':
                return "SuperAdmin"
            }
          }
        },
        {
          key: 'promotions',
          db: `promotions: groups{
                        promotion: group {
                            identifier
                            name
                        }
                    }`,
          label: 'Promotion(s)',
          sortable: true,
          formatter: (values) => {
            if (!values || values.length <= 0)
              return 'Aucun'

            return values.map(value => [value.promotion.identifier, value.promotion.name].filter(str => str || false).join(' - ')).join(', ') || '~ Promotion supprimée ~'
          }
        },
        {
          key: 'disabled',
          db: `
            disabled
            cognito_id
            cognito_confirmed
            groups {
                group {
                    disabled
                    start_date
                    end_date
                }
            }`,
          label: 'Statut',
          sortable: true,
          formatter: (value, key, user) => {
            // Check if the user group is not disabled and still active (start/end date)
            const hasGroup = (user.groups.length > 0)
            let isGroupActive = (hasGroup && !user.groups[0].group.disabled)

            if (isGroupActive) {
              const startDate = new Date(user.groups[0].group.start_date)
              const endDate = new Date(user.groups[0].group.end_date)

              isGroupActive = (now > startDate.getTime() && now < endDate.getTime())
            }

            if (user.disabled) {
              return userFieldsValues.stateMap['disabled'].label
            } else if (hasGroup && !isGroupActive) {
              return userFieldsValues.stateMap['group_disabled'].label
            } else if (hasGroup && isGroupActive && (!user.cognito_id || !user.cognito_confirmed)) {
              return userFieldsValues.stateMap['inactive'].label
            } else if (user.cognito_id) {
              if (user.cognito_confirmed) {
                return userFieldsValues.stateMap['active'].label
              }

              return userFieldsValues.stateMap['pending'].label
            }

            return userFieldsValues.stateMap['exist'].label
          }
        },
      ]
    }
  },
  props: {
    previousPage: {
      type: Number,
      required: false,
      default: null
    }
  },
  components: { 'cooc-list': List },
  computed: {
    ...mapState('Auth', ['userInfo']),
    isSuperAdmin() {
      return (this.userInfo && this.userInfo.role == 'superadmin')
    },
    isReadOnly() {
      return !this.isSuperAdmin
    },
    filters() {
      if (this.isSuperAdmin) {
        return [
          {
            key: 'role',
            label: 'Rôle'
          }
        ]
      }

      return []
    }
  },
  async mounted() {
    this.isLoading = true

    this.level1 = await this.$store.dispatch('Course/GetCourseByType', 'mission')
    this.level2 = await this.$store.dispatch('Course/GetCourseByType', 'investigation')

    this.isLoading = false
  },
  methods: {
    ...mapActions('EditItem', ['clearCurrentID']),
    addUser() {
      this.clearCurrentID();
      this.$router.push({ name: 'users-create' });
    },
    importUserList() {
      // console.log("IMPORT USER LIST");
    }
  }
}
</script>
<style lang="scss">
// .user-buttons{
//     flex-basis: 33%;
// }
</style>