<template>
  <div v-if="event">
    <b-card>
      <div class="flex flex-col">
        <div class="text-2xl font-bold">
          Rendez-vous prévu le {{ new Date(event.start_at).toLocaleString() }}
        </div>

        <div class="mt-4">
          <b-button @click="joinAsAdmin"
            >Rejoindre la session en tant qu'administrateur</b-button
          >
        </div>
        <div class="mt-4">
          <b-button @click="createLink"
            >Créer un lien d'invitation pour utilisateur lambda</b-button
          >
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";

export default {
  props: {
    itemID: {
      type: String,
      required: false,
      default: null,
    },
  },

  data: () => ({
    event: null,
  }),

  computed: {
    ...mapState("Auth", ["user"]),
  },

  watch: {
    itemID: {
      immediate: true,
      async handler(id) {
        if (id) {
          this.event = await this.getEventById(id);
        }
      },
    },
  },

  methods: {
    ...mapActions("Rxs", ["getEventById"]),

    async joinAsAdmin() {
      const token = await axios.post(
        "https://api.rxs.live/liveconnect",
        {},
        {
          headers: {
            Authorization: `Bearer ${this.user.id.token}`,
          },
        }
      );

      // TODO: Add Cognito to lambda pool verifier
    },

    async createLink() {
      const response = await axios.post("https://api.rxs.live/liveconnect");

      const token = response.data.message.livetoken;

      navigator.permissions
        .query({ name: "clipboard-write" })
        .then((result) => {
          if (result.state == "granted" || result.state == "prompt") {
            const baseUrl = "http://afpols-visio.julien-ripouteau.com/#/";
            const url = `${baseUrl}?event=${this.event.id}&token=${token}`;

            navigator.clipboard.writeText(url);

            this.$bvToast.toast(
              "Le lien d'invitation a été copié dans votre presse papier",
              { title: `Succès !` }
            );
          }
        });
    },
  },
};
</script>
