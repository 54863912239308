<template>
  <div>
    <List
      v-if="!isLoading"
      listing="group"
      :fields="fields"
      order-by="end_date: desc, start_date: asc"
      search-text="Rechercher une promotion"
      :pageSize="pageSize"
      edit-route-name="promotions-edit"
    >
      <template v-slot:buttons>
        <div class="md:flex flex-row justify-around hidden md:w-1/3">
          <button
            v-if="!isReadOnly"
            type="button"
            class="twn-button whitespace-no-wrap overflow-hidden text-xs mr-2"
            @click="onAddPromotionClick"
          >
            Ajouter une nouvelle promotion
          </button>
        </div>
      </template>
    </List>
  </div>
</template>

<script>
import List from "@/components/List";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    List,
  },
  props: {
    pageSize:{
      type: Number,
      default: 30,
      required: false
    }
  },
  data() {
    return {
      isLoading: false,
      userAnalytics: [],
      fields: [
        {
          key: "identifier",
          label: "Identifiant",
          sortable: true,
        },
        {
          key: "name",
          label: "Nom",
          sortable: true,
        },
        {
          key: 'users_aggregate',
          label: "Stagiaires",
          sortable: true,
          db: `users_aggregate {
            aggregate {
              count
            }
          }`,
          nested: 'count'
        },
        {
          key: 'start_date',
          db: `start_date
          end_date`,
          label: "Dates",
          sortable: true,
          formatter: (value, key, item) => {
            const start = (item.start_date ? item.start_date.split('-').reverse().join('/') : '')
            const end = (item.end_date ? item.end_date.split('-').reverse().join('/') : '')

            return start + ' - ' + end
          }
        },
        {
          key: 'disabled',
          label: 'Statut',
          sortable: true,
          formatter: (value, key, item) => {
            if (value)
              return 'Suspendue'

            const now = new Date()
            const start = new Date(item.start_date)
            const end = new Date(item.end_date)

            return (now < start ? 'À venir' : (now > end ? 'Passé' : 'En cours'))
          }
        },
        {
          key: 'id',
          label: 'Capsules envoyées',
          sortable: false,
          formatter: (value, key, group) => {
            let sentSteps = 0
            let stepsCount = 0

            this.userAnalytics.filter((analytics) => {
              if (analytics.data_key === group.id) {
                if (stepsCount === 0) {
                  stepsCount = analytics.data.journey_steps_count
                }

                if (analytics.data.sent_steps_count > sentSteps) {
                  sentSteps = analytics.data.sent_steps_count
                }
              }
            })

            return (stepsCount <= 0 ? '-' : sentSteps + ' / ' + stepsCount)
          }
        }
      ],
    }
  },
  computed: {
    ...mapState('Auth', [ 'userInfo' ]),
    isSuperAdmin() {
      return (this.userInfo && this.userInfo.role == 'superadmin')
    },
    isReadOnly() {
      return !this.isSuperAdmin
    }
  },
  async mounted() {
    this.isLoading = true
    this.userAnalytics = await this.$store.dispatch('Logs/GetUsersAnalyticsData')
    this.isLoading = false
  },
  methods: {
    ...mapActions("EditItem", ["clearCurrentID"]),
    onAddPromotionClick() {
      this.clearCurrentID();
      this.$router.push({ name: "promotions-create" });
    },
  },
};
</script>