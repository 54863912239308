<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { mapActions } from "vuex";

const default_layout = "default";

export default {
  created() {
    this.init();
  },

  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
  },

  methods: {
    ...mapActions("Rxs", ["init"]),
  },
};
</script>

<style lang="scss">
@namespace ct "http://gionkunz.github.com/chartist-js/ct";

#app {
  @apply font-principal text-principal-gray;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow-x: hidden;
  
  .container {
    @apply p-8;
  }
}
</style>
