<template>
  <div class="games">
    <!-- Add game by type -->
    <div class="choice">
      <div class="" v-for="gameType in gameTypeList" :key="gameType.id">
        <router-link
          :to="{ name: 'game-edit', params: { gameTypeSlug: gameType.slug } }"
        >
          <div class="game-card twn-card">
            <div
              class="uppercase text-sm w-full text-center font-principal-bold"
            >
              {{ gameType.name }}
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <!-- Game list -->
    <List
      listing="activity"
      :fields="fields"
      :filters="filters"
      searchText="Rechercher un minijeu"
      editRouteName="game-edit"
      :customRouteParams="customRouteParams"
      :deleteItemOverride="deleteGameByID"
    >
    </List>
  </div>
</template>

<script>
import List from "@/components/List";
import { mapState, mapActions } from "vuex";

export default {
  name: "MiniGamesList",
  components: { List },
  created() {
    this.getTypeList();
  },
  data() {
    return {
      fields: [
        {
          key: "identifier",
          label: "Identifiant",
          sortable: true,
        },
        {
          key: "title",
          label: "Titre",
          sortable: true,
        },
        {
          key: "type",
          db: `type {
                        name,
                        slug
                    }`,
          nested: "name",
          label: "Type",
          sortable: true,
        },
        {
          label: "Utilisations",
          sortable: true,
          key: "usedInNodes_aggregate",
          nested: "count",
          db: `
                    usedInNodes_aggregate {
                        aggregate {
                            count
                        }
                    }
                    `,
        },
        {
          key: "scores",
          db: `scores {
                        jauge: type {
                            name
                        }
                        score
                    }`,
          label: "Jauges et Scores",
        },
      ],
      filters: [
        {
          key: "type",
          label: "Type",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      gameTypeList: (state) => state.Games.type_list,
    }),
  },
  methods: {
    ...mapActions("Games", ["getTypeList", "deleteGameByID"]),
    customRouteParams(item) {
      return {
        gameTypeSlug: item.nested_type.slug,
      };
    },
  },
};
</script>

<style lang="scss">
.choice {
  // box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.1);
  @apply flex-wrap justify-center w-full flex flex-row mb-5;
  @screen lg {
    @apply justify-start;
  }
  .game-card {
    @apply flex flex-col cursor-pointer m-4;
    @screen lg {
      @apply m-0 mr-4 mb-4;
    }

    .picto-image {
      height: 90%;
      @apply text-principal-selected fill-current;
      svg {
        width: 100%;
        height: 100%;
      }
    }

    &:hover {
      box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.15);
    }
  }
}
</style>
