<template>
  <div>
    <List
      ref="list"
      listing="events"
      apollo-client="rxs"
      :fields="fields"
      :where="`application_id: { _eq: &quot;${applicationId}&quot; }`"
      searchText="Rechercher une session"
      editRouteName="session-edit"
    >
      <template v-slot:buttons>
        <div class="md:flex flex-row justify-around hidden md:w-1/3">
          <button
            type="button"
            class="twn-button whitespace-no-wrap overflow-hidden text-xs mr-2"
            @click="createSession"
          >
            Créer une session
          </button>
        </div>
      </template>
    </List>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import rxsApollo from "@/rxsApolloClient";
import gql from "graphql-tag";
import { GC_INSERT_EVENT } from "@/graphql/rxs";
import List from "@/components/List";

export default {
  components: { List },
  data: () => ({
    fields: [
      {
        key: "id",
        label: "Id",
      },
      {
        key: "event_status",
        nested: "name",
        db: `event_status {
            name
          }`,
        label: "Status",
      },
      {
        key: "start_at",
        label: "Début",
      },
      {
        key: "end_at",
        label: "Fin",
      },
    ],
  }),

  computed: { ...mapState("Rxs", ["applicationId"]) },

  methods: {
    async createSession() {
      const value = await this.$bvModal.msgBoxConfirm(
        "Êtes vous sur de vouloir créer une nouvelle session ?",
        {
          title: "Créer une session",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        }
      );

      if (!value) return;

      const endAt = new Date()
      endAt.setHours(new Date().getHours() + 2)

      await rxsApollo.mutate({
        mutation: GC_INSERT_EVENT,
        variables: {
          applicationId: this.applicationId,
          start_at: new Date(),
          end_at: endAt,
          status_id: 4
        },
      });

      this.$refs.list.getList()
    },
  },
};
</script>
