<template>
  <div class="c-promotion-detail">
    <!-- Top buttons -->
    <div class="buttons flex flex-row justify-around md:justify-between">
      <button type="button" class="twn-button text-xs" @click="goBack">Retour</button>
      <div v-if="!isReadOnly">
        <button
          type="button"
          class="twn-button text-xs danger mr-4"
          @click="showDeleteModal()"
          v-if="itemID"
          :disabled="hasPendingStoreRequest"
        >Supprimer la promotion</button>
        <button
          type="button"
          class="twn-button text-xs"
          @click="save"
          :disabled="hasPendingStoreRequest"
        >Enregistrer</button>
      </div>
    </div>

    <div class="my-10">
      <div class="flex">
        <div
          v-if="!itemID"
          class="flex-grow uppercase font-principal-bold text-sm"
        >Ajouter une promotion</div>
        <div v-else class="flex-grow uppercase font-principal-bold text-sm">Éditer la promotion</div>
        <div>
          <span>Suspendue</span>
          <Toggle
            :disabled="isReadOnly"
            :checked="!promotion.disabled"
            :onInput="() => { promotion.disabled = !promotion.disabled }"
            class="mx-4"
          ></Toggle>
          <span>Active</span>
        </div>
      </div>

      <!-- Group common fields -->
      <div class="mt-8 mb-4">
        <!-- Identifier -->
        <div class="form-group inline-block w-1/3 pr-2">
          <label for="game-name" class="uppercase font-principal-medium text-sm">Identifiant</label>
          <b-form-input
            :disabled="isReadOnly"
            class="w-full"
            v-model="promotion.identifier"
            type="text"
            autofocus
          />
        </div>

        <!-- Name -->
        <div class="form-group inline-block w-2/3 pl-2">
          <label for="game-name" class="uppercase font-principal text-sm">Nom</label>
          <b-form-input
            :disabled="isReadOnly"
            class="w-full"
            v-model="promotion.name"
            type="text"
            autofocus
          />
        </div>

        <!-- Start date -->
        <div class="form-group inline-block w-1/3 pr-2">
          <label>Date d'ouverture</label>
          <b-form-datepicker
            :disabled="isReadOnly || !!promotion.id"
            class="w-full"
            v-model="promotion.startDate"
            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'long' }"
          ></b-form-datepicker>
        </div>

        <!-- AWKN Journeys templates -->
        <div v-if="!promotion.id">
          <label>Modèle de Journey AWKN</label>

          <v-select
            placeholder="Choisir un modèle de Journey AWKN"
            :options="awknJourneyTemplateList"
            v-model="promotion.awknJourneyTemplateId"
            label="training_name"
            :reduce="journey => journey.id"
          >
            <template
              v-slot:option="option"
            >{{ option.training_name }} #{{ option.id }}</template>
          </v-select>

          <div v-if="promotion.awknJourneyTemplateId" class="mt-2">
            <a :href="`https://app.awkn.fr/trainings/${promotion.awknJourneyTemplateId}/steps`" target="_blank" class="text-sm">Voir le modèle de Journey sur AWKN</a>
          </div>
        </div>

        <!-- AWKN Journeys -->
        <div v-else>
          <label>Journey AWKN</label>

          <v-select
            placeholder="Associer la promo à une Journey AWKN"
            :options="awknJourneyList"
            :value="promotion.awknJourneyId"
            label="training_name"
            :getOptionLabel="getJourneyLabel"
            :reduce="journey => journey.id"
            :disabled="!!promotion.awknJourneyId"
            :clearable="!promotion.awknJourneyId"
          >
            <template
              v-slot:option="option"
            >#{{ option.id }} - {{ option.training_name }} - Débute le {{ option && option.start_date && option.start_date.split('-').reverse().join('/') }}</template>
          </v-select>

          <div v-if="awknJourney" class="mt-2">
            <a :href="`https://app.awkn.fr/trainings/${promotion.awknJourneyId}/steps`" target="_blank" class="text-sm">Voir la Journey sur AWKN</a>
          </div>
        </div>

        <div class="mt-8">
          <div class="tabs-triggers flex rounded border border-gray-light">
            <button
              :class="{ 'selected': (currentTab == 'users') }"
              type="button"
              @click="currentTab = 'users'"
            >Utilisateurs</button>
          </div>

          <PromotionInternsForm
            v-show="currentTab == 'users'"
            :promotion="promotion"
            @add-intern="onAddIntern"
            @remove-intern="onRemoveIntern"
            :is-read-only="isReadOnly"
          />
        </div>
      </div>

      <GroupDashboard v-if="awknJourney && promotion && promotion.id" :id="promotion.id" :journey="awknJourney" />
    </div>

    <!-- Modals -->
    <b-modal
      ref="delete-modal-detail-promotion"
      class="bootstrap"
      centered
      hide-footer
      id="delete-modal-detail-promotion"
      hide-header
    >
      <div class="d-block text-center my-6 uppercase font-semibold">
        <h3>Confirmer la suppression de "{{ promotion.name }}"</h3>
      </div>
      <div class="flex flex-row justify-evenly items-center">
        <button
          type="button"
          class="mt-4 twn-button"
          @click="$bvModal.hide('delete-modal-detail-promotion')"
        >Retour</button>
        <button type="button" class="mt-4 twn-button danger" @click="onConfirmDelete">Supprimer</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dispatchStoreRequest from "@/mixins/dispatchStoreRequest"
import Toggle from '@/components/Toggle'
import PromotionInternsForm from "./PromotionInternsForm"

import GroupDashboard from '@/components/dashboard/Group'

export default {
  props: ['itemID'],
  mixins: [dispatchStoreRequest],
  components: {
    Toggle,
    PromotionInternsForm,
    GroupDashboard,
  },
  data: () => ({
    // UI State
    currentTab: 'users',
    // Local promotion data
    usersInSameJourney: [],
    promotion: {
      id: null,
      identifier: '',
      name: '',
      startDate: new Date(),
      endDate: null,
      course: null,
      customer_id: null,
      disabled: true,
      type: null,
      sequences: [],
      users: [],
      awknJourneyId: null,
      awknJourneyTemplateId: null,
    },
    course: null,
    awknJourneys: [],
  }),
  computed: {
    ...mapState({
      courseList: state => state.Course.list,
      typeList: state => state.Promotion.typeList,
      customerList: state => state.Customers.list,
      userInfo: state => state.Auth.userInfo,
      userList: state => state.Utils.userList
    }),
    isSuperAdmin() {
      return (this.userInfo && this.userInfo.role == 'superadmin')
    },
    isReadOnly() {
      return !this.isSuperAdmin
    },
    awknJourneyList() {
      if (!this.awknJourneys) {
        return []
      }

      return this.awknJourneys.filter((journey) => {
        return !journey.template_status
      })
    },
    awknJourneyTemplateList() {
      if (!this.awknJourneys) {
        return []
      }

      return this.awknJourneys.filter((journey) => {
        return journey.template_status
      })
    },
    awknJourney() {
      if (!this.awknJourneys || !this.promotion.awknJourneyId) {
        return null
      }

      return this.awknJourneys.find((journey) => journey.id === this.promotion.awknJourneyId)
    }
  },
  watch: {
    itemID: {
      async handler(itemID) {
        this.awknJourneys = await this.dispatchStoreRequest('Journey/getList')

        if (itemID) {
          // todo: handle invalid uuid response
          await this.dispatchStoreRequest('Promotion/getByID', itemID, true)

          // Convert remote data to local one
          const remoteData = this.$store.state.Promotion.items[itemID]

          this.promotion.id = itemID

          // todo: handle error
          if (!remoteData)
            return

          this.promotion.identifier = remoteData.identifier
          this.promotion.name = remoteData.name
          this.promotion.awknJourneyId = remoteData.awkn_journey_id
          this.promotion.awknJourneyTemplateId = null
          this.promotion.startDate = remoteData.start_date
          this.promotion.endDate = remoteData.end_date
          this.promotion.course = remoteData.course_id
          this.promotion.customer_id = remoteData.customer_id
          this.promotion.disabled = remoteData.disabled
          this.promotion.scenarios = null //Set scenario var for log data
          this.promotion.type = remoteData.promotion_type_id
          this.promotion.users = remoteData.users.map((user) => {
            return {
              id: user.user_id,
            }
          })
        } else {
          // Clear local data
          this.promotion.id = null
          this.promotion.identifier = ''
          this.promotion.name = ''
          this.promotion.startDate = new Date()
          this.promotion.endDate = null
          this.promotion.course = null
          this.promotion.customer_id = null
          this.promotion.disabled = true
          this.promotion.sequences = []
          this.promotion.users = []
          this.promotion.scenarios = null
          this.promotion.awknJourneyId = null
          this.promotion.awknJourneyTemplateId = null

          // Set default values if possible
          if (!this.promotion.type && this.typeList && this.typeList.length > 0 && this.typeList[0].id) {
            this.promotion.type = this.typeList[0].id
          } else {
            this.promotion.type = null
          }
        }
      },
      immediate: true,
    },
    'promotion.startDate': {
      handler(startDate) {
        // Automatically set end date to start date + 1 year
        if (startDate) {
          let nextYear = new Date(startDate)
          nextYear.setFullYear(nextYear.getFullYear() + 1)

          this.promotion.endDate = nextYear
        }
      },
      immediate: true,
    },
    typeList(list) {
      // Set default values
      if (!this.promotion.type && list && list.length > 0 && list[0].id) {
        this.promotion.type = list[0].id
      }
    },
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'promotions-list' })
    },
    showDeleteModal() {
      this.$refs['delete-modal-detail-promotion'].show()
    },
    async onConfirmDelete() {
      await this.$store.dispatch('Promotion/delete', this.itemID)
      this.$bvModal.hide('delete-modal-detail-promotion')
      this.goBack()
    },
    onAddIntern(intern) {
      this.promotion.users.unshift({ id: intern.id })
    },

    onRemoveIntern(intern) {
      for (var i = 0; i < this.promotion.users.length; i++) {
        if (this.promotion.users[i].id == intern.id) {
          this.promotion.users.splice(i, 1)
          break
        }
      }
    },

    async save() {
      if (!this.itemID && !this.promotion.awknJourneyTemplateId) {
        alert('Vous devez d\'abord choisir un modèle de Journey AWKN')
        return
      }

      const response = await this.dispatchStoreRequest('Promotion/save', { data: this.promotion, journeys: this.awknJourneys })

      if (!this.itemID && response.id) {
        this.awknJourneys = await this.dispatchStoreRequest('Journey/getList', null, true)
        
        this.$router.push({
          name: 'promotions-edit',
          params: {
            itemID: response.id,
          }
        })
      }

      this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })
    },
    getJourneyLabel(option) {
      if (!option)
        return '~ Élément supprimé ~'

      return `#${option.id} - ${option.training_name} - Débute le ${option.start_date && option.start_date.split('-').reverse().join('/')}`
    },
  },
}
</script>

<style lang="scss" scoped>
.tabs-triggers {
  button {
    @apply flex-1 p-2 font-principal-medium;

    &.selected {
      @apply text-white bg-red;
    }
  }
}
</style>
