import commonTypes from "../common/commonTypes"

import { groupAnalytics } from '../common/groupsData'
import { getUsersAnalytics, usersAnalytics } from '../common/usersData'

export default {
	types: commonTypes,
	remoteData: {
		users: {
			module: 'Logs',
			action: 'GetGroupUsersTrackingData',
			payload: {
				roles: ['user'],
			},
		},
	},
	extraData: {
		usersAnalytics: (data) => getUsersAnalytics(data.users),
		groupData: (data) => groupAnalytics(data.usersAnalytics),
		usersData: (data) => usersAnalytics(data.usersAnalytics),
	},
	sheets: []
}