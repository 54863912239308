export function journeysRates(journeys) {
	const rates = {
		open: 0,
		click: 0,
	}

	if (!journeys) {
		return rates
	}

	let journeyWithDataCount = 0

	journeys.forEach((journey) => {
		if (!journey.open_rate || !journey.click_rate) {
			return
		}

		journeyWithDataCount += 1
		rates.open += journey.open_rate
		rates.click += journey.click_rate
	})

	// Compute average rates
	rates.open = (rates.open / (journeyWithDataCount || 1))
	rates.click = (rates.click / (journeyWithDataCount || 1))

	return rates
}