<template>
	<p v-if="isLoading || !trackingData">
		Chargement...
	</p>
	<div v-else class="dashboard-user">
		<CategoryBlock
			v-for="groupData in groupsData"
			:key="groupData.id"
			:title="'Statistiques pour la journey ' + [groupData.training_name, groupData.group_name].filter(value => !!value).join(' - ')"
		>
			<a
				v-if="groupData.analytics_shared_url"
				:href="groupData.analytics_shared_url"
				target="_blank"
				class="underline italic"
			>
				Voir les statistiques de la journey sur AWKN
			</a>

			<!-- Group and user KPIs -->
			<div class="dashboard-user-kpis mt-4 mb-8">
				<KPIBlock
					title="Capsules envoyées"
					:raw="(groupData.journey_steps_count ? groupData.sent_steps_count + ' / ' + groupData.journey_steps_count : null)"
					backgroundColor="bg-dashboard-blue-dark"
				/>
				<KPIBlock
					title="Taux de complétude"
					:percentage="groupData.completion_rate || null"
					backgroundColor="bg-dashboard-green"
				/>
				<KPIBlock
					title="Temps passé"
					:time="(groupData.time_spent_in_seconds ? groupData.time_spent_in_seconds * 1000 : null)"
				/>
			</div>

			<!-- User open/click rates KPIs -->
			<div class="dashboard-user-kpis">
				<KPIBlock
					title="Taux d’ouverture"
					:percentage="groupData.open_rate || null"
					help="Nombre d'ouvertures uniques divisé par le nombre d'emails envoyés hors relance."
				/>
				<KPIBlock
					title="Taux de clic"
					:percentage="groupData.click_rate || null"
					help="Nombre de clics uniques divisé par le nombre de capsules cliquables envoyées."
				/>
			</div>
		</CategoryBlock>
	</div>
</template>

<script>
	import dispatchStoreRequest from "@/mixins/dispatchStoreRequest"

	import trackingReport from '@/utils/trackingReport'

	import trackingReportTemplate from "@/constants/userTrackingReportTemplate"

	import CategoryBlock from "./block/Category"
	import KPIBlock from "./block/KPI"

	export default {
		name: 'UserDashboard',
		mixins: [dispatchStoreRequest],
		components: {
			CategoryBlock,
			KPIBlock
		},
		props: {
			user: {
				type: Object,
				required: true
			},
			groupList: {
				type: Array,
				required: true
			}
		},
		data() {
			return {
				isLoading: false,
				trackingData: null,
				journeys: null,
			}
		},
		computed: {
			groupsData() {
				if (!this.journeys) {
					return {}
				}

				const journeyByGroupId = this.journeys.reduce((dict, journey) => {
					if (journey.group?.id) {
						dict[journey.group.id] = journey
					}

					return dict
				}, {})

				const groupsData = this.user.groups.reduce((dict, group) => {
					if (journeyByGroupId[group.id]) {
						dict[group.id] = {
							id: group.id,
							training_name: journeyByGroupId[group.id].training_name,
							group_name: journeyByGroupId[group.id].group_name,
							analytics_shared_url: journeyByGroupId[group.id].analytics_shared_url,
						}
					}

					return dict
				}, {})

				// Merge user current group and group we have tracking data for
				if (!this.trackingData?.user?.byGroup) {
					return groupsData
				}

				const trackedGroupIds = Object.keys(this.trackingData.user.byGroup)

				trackedGroupIds.forEach((id) => {
					// Fill/Add group tracking data
					if (journeyByGroupId[id] && !groupsData[id]) {
						groupsData[id] = {
							id,
							training_name: journeyByGroupId[id].training_name,
							group_name: journeyByGroupId[id].group_name,
							analytics_shared_url: journeyByGroupId[id].analytics_shared_url,
						}
					}

					const groupData = this.trackingData.user.byGroup[id].data
					
					if (!groupData) {
						return
					}

					groupsData[id].sent_steps_count = groupData.sent_steps_count
					groupsData[id].journey_steps_count = groupData.journey_steps_count
					groupsData[id].completion_rate = groupData.completion_rate
					groupsData[id].time_spent_in_seconds = groupData.time_spent_in_seconds
					groupsData[id].open_rate = groupData.open_rate
					groupsData[id].click_rate = groupData.click_rate
				})

				return groupsData
			},
		},
		watch: {
			user: {
				async handler(user) {
					this.isLoading = true

					this.journeys = await this.dispatchStoreRequest('Journey/getList')

					// Load tracking data
					this.trackingData = await trackingReport.loadData(this.$store, trackingReportTemplate, { id: user.id, groups: user.groups })

					this.isLoading = false
				},
				immediate: true
			}
		},
	}
</script>

<style lang="scss">
	.dashboard-user {
		.dashboard-category {
			@apply w-full max-w-screen-lg mb-5 mx-auto;

			& > p {
				margin-bottom: 0;
			}
		}

		&-kpis {
			@apply flex flex-wrap justify-between mb-8;

			.dashboard-kpi {
				@apply flex-1 mx-4;

				&:first-child {
					@apply ml-0;
				}

				&:last-child {
					@apply mr-0;
				}
			}
		}
	}
</style>