export function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        }, wait);
        if (immediate && !timeout) func.apply(context, args);
    };
  }

export function parseJWT(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function leadingZero(number) {
    return (number < 10 && number > 0 ? '0' + number : number)
}

export function formatTime(value) {
    const time = parseFloat(value)

    if (isNaN(time)) {
        return '-'
    }

    const oneHour = (1 * 60 * 60 * 1000)

    let remainingTime = (time / oneHour)
    const hours = Math.floor(remainingTime)

    remainingTime = (remainingTime - hours) * 60
    const minutes = Math.floor(remainingTime)

    remainingTime = (remainingTime - minutes) * 60
    const seconds = Math.round(remainingTime)

    return (hours > 0 ? hours + 'h ' : '') + (minutes > 0 || seconds <= 0 ? leadingZero(minutes) + 'min ' : '') + (hours <= 0 && seconds > 0 ? leadingZero(seconds) + 's' :  '')
}

export function formatDate(date) {
    return [leadingZero(date.getDate()), leadingZero(date.getMonth() + 1), date.getFullYear()].join('/')
}

export function formatPercentage(value) {
  const percent = parseFloat(value)

  if (isNaN(percent)) {
    return '-'
  }

  return Math.round(percent * 100) + ' %'
}

// TODO: Don't store user with a refresh token because we can use the refresh token cookie to auth between refresh/tabs ?
export const userStorage = {
    storageKey: 'user',

    get() {
        const userJSON = localStorage.getItem(this.storageKey)

        if (!userJSON)
            return null

        try {
            const user = JSON.parse(userJSON)

            return user
        } catch (error) {
            return null
        }
    }, 

    set(user) {
        localStorage.setItem(this.storageKey, JSON.stringify(user))
    }, 

    clear() {
        localStorage.removeItem(this.storageKey)
    }
}