import gql from "graphql-tag";

export const EVENT_FRAGMENT = gql`
  fragment EventFragment on events {
    client_id
    created_at
    end_at
    meta_infos
    notes
    password
    positions
    start_at
    start_time
    status_id
    token_url
    updated_at
    user_id
    id
  }
`;

export const GC_GET_APPLICATION_ID = gql`
  query GetApplicationId {
    application(where: { title: { _eq: "oriente-sol" } }) {
      id
      title
      application_config_id
    }
  }
`;

export const GC_GET_EVENTS = gql`
  ${EVENT_FRAGMENT}

  query GetEvents($applicationId: uuid!) {
    events(where: { application_id: { _eq: $applicationId } }) {
      ...EventFragment
    }
  }
`;

export const GC_GET_EVENT_BY_ID = gql`
  ${EVENT_FRAGMENT}

  query GetEventById($id: uuid!) {
    events_by_pk(id: $id) {
      ...EventFragment
    }
  }
`;

export const GC_INSERT_EVENT = gql`
  ${EVENT_FRAGMENT}

  mutation InsertEvent(
    $applicationId: uuid!
    $start_at: date!
    $end_at: date!
    $status_id: Int!
  ) {
    insert_events_one(
      object: {
        application_id: $applicationId
        start_at: $start_at
        end_at: $end_at
        status_id: $status_id
      }
    ) {
      ...EventFragment
    }
  }
`;
