import gql from "graphql-tag"

const GC_GET_LOG_TYPES = gql`
query GetLogTypes{
	log_type {
		description
		id
		name
		slug
	}
}
`

const GC_CHECK_GROUP_ANALYTICS_DATA = gql`
query CheckGroupAnalyticsData($id: uuid!) {
	user_log(where: {type: {slug: {_eq: "analytics"}}, data_key: {_eq: $id}}, limit: 1) {
		updated_at
	}
}
`

const GC_GET_USERS_ANALYTICS_DATA = gql`
query GetUsersAnalyticsData {
	user_log(where: {type: {slug: {_eq: "analytics"}}}) {
		data_key
		data
		updated_at
	}
}
`

const GC_INSERT_USER_LOGS = gql`
mutation InsertUserLogs($logs: [user_log_insert_input!]!) {
	insert_user_log(objects: $logs) {
		affected_rows
	}
}
`

const GC_INSERT_CONTENT_LOG = gql`
mutation InsertContentLog($obj:content_log_insert_input!) {
	insert_content_log_one(object: $obj) {
		id
	}
}
`

const GC_CLEAR_USER_LOGS = gql`
mutation ClearUserLogs($data_key: uuid!, $log_type_id: uuid!) {
	delete_user_log(where: {data_key: {_eq: $data_key}, log_type_id: {_eq: $log_type_id}}) {
		affected_rows
	}
}
`

const GC_GET_COURSES_TRACKING_DATA = gql`
query GetCourseTrackingData {
	course {
		id
		type {
			slug
		}
		modules {
			id
		}
	}
}
`

const GC_GET_GROUPS_TRACKING_DATA = gql`
query GetGroupsTrackingData {
	total: group_aggregate {
		aggregate {
			count
		}
	}
	opened: group_aggregate(where: {start_date: {_lte: "now"}, disabled: {_eq: false}}) {
		aggregate {
			count
		}
	}
}
`

const GC_GET_USERS_TRACKING_DATA = gql`
query GetUsersTrackingData($roles: [String!]!) {
	user(where: { role: { _in: $roles } }) {
		id
		groups {
			group_id
		}
		user_logs(where: {type: {slug: {_eq: "analytics"}}}) {
			data_key
			data
			updated_at
		}
	}
	group {
		id
		users_aggregate {
			aggregate {
				count
			}
		}
	}
}
`

const GC_GET_CUSTOMER_GROUPS_TRACKING_DATA = gql`
query GetCustomerGroupsTrackingData($id: uuid!) {
	customer_by_pk(id: $id) {
		list: groups {
			id
		}
		total: groups_aggregate {
			aggregate {
				count
			}
		}
		opened: groups_aggregate(where: {start_date: {_lte: "now"}, disabled: {_eq: false}}) {
			aggregate {
				count
			}
		}
	}
}
`

const GC_GET_CUSTOMER_USERS_TRACKING_DATA = gql`
query GetCustomerUsersTrackingData($id: uuid!, $roles: [String!]!) {
	customer_by_pk(id: $id) {
		groups {
			id
			users(where: { user: { role: { _in: $roles } } }) {
				user {
					id
					user_logs(where: {type: {slug: {_eq: "analytics"}}}) {
						data_key
						data
						updated_at
					}
				}
			}
		}
	}
}
`

const GC_GET_GROUP_USERS_TRACKING_DATA = gql`
query GetGroupUsersTrackingData($id: uuid!, $roles: [String!]!) {
	group_by_pk(id: $id) {
		users(where: { user: { role: { _in: $roles } } }) {
			user {
				id
				user_logs(where: {type: {slug: {_eq: "analytics"}}, data_key: {_eq: $id}}) {
					data
					updated_at
				}
			}
		}
	}
}
`

const GC_GET_USER_TRACKING_DATA = gql`
query GetUserTrackingData($id: uuid!) {
	user_by_pk(id: $id) {
		id
		user_logs(where: {type: {slug: {_eq: "analytics"}}}) {
			data_key
			data
			updated_at
		}
	}
}
`

export {
	GC_GET_LOG_TYPES,
	GC_CHECK_GROUP_ANALYTICS_DATA,
	GC_GET_USERS_ANALYTICS_DATA,
	GC_INSERT_USER_LOGS,
	GC_INSERT_CONTENT_LOG,
	GC_CLEAR_USER_LOGS,
	GC_GET_COURSES_TRACKING_DATA,
	GC_GET_GROUPS_TRACKING_DATA,
	GC_GET_USERS_TRACKING_DATA,
	GC_GET_CUSTOMER_GROUPS_TRACKING_DATA,
	GC_GET_CUSTOMER_USERS_TRACKING_DATA,
	GC_GET_GROUP_USERS_TRACKING_DATA,
	GC_GET_USER_TRACKING_DATA,
}
