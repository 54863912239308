import { computeTrackingDataForList, maxField, averageField } from '@/utils/trackingDataFactories'

export function groupAnalytics(usersAnalytics) {
	// Compute active user count, user time average and max sent steps
	const analytics = computeTrackingDataForList(usersAnalytics, {
		sentSteps: maxField('sent_steps_count'),
	})

	if (usersAnalytics.length > 0) {
		analytics.stepsCount = usersAnalytics[0].journey_steps_count
	} else {
		analytics.stepsCount = 0
	}

	return analytics
}