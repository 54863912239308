import gql from 'graphql-tag'

const GC_GET_JOURNEY_LIST = gql`
query GetJourneyList {
	journey {
		id
		training_name
		group_name
		start_date
		template_status
		open_rate
		click_rate
		analytics_shared_url
		group {
			id
		}
	}
}
`

const GC_GET_JOURNEY_CONTACTS = gql`
query GetJourneyContacts($id: Int!) {
	journey_by_pk(id: $id) {
		contacts {
			id
			first_name
			last_name
			email
			blocked_status
			created_at
		}
	}
}
`

const GC_GET_JOURNEY_CONTACT_ANALYTICS = gql`
query GetJourneyContactAnalytics($id: Int!) {
	contact_analytics_by_journey_id(id: $id) {
		email
		journey_steps_count
		sent_steps_count
		open_rate
		click_rate
		completion_rate
		mcq_avg_rate
		time_spent_in_seconds
	}
}
`

const GC_UPDATE_JOURNEY = gql`
mutation UpdateJourney($id: Int!, $journey: journey_set_input!) {
	update_journey_by_pk(pk_columns: {id: $id}, _set: $journey) {
		id
	}
}
`

const GC_DUPLICATE_JOURNEY = gql`
mutation DuplicateJourney($id: Int!, $group_name: String!, $start_date: String!, $training_name: String!) {
	duplicate_journey_by_pk(pk_columns: {id: $id}, _set: {start_date: $start_date, group_name: $group_name, training_name: $training_name}) {
		id
	}
}
`

const GC_ADD_JOURNEY_CONTACT = gql`
mutation AddJourneyContact($contact: journey_contact_insert_input!) {
	insert_journey_contact_one(object: $contact) {
		id
	}
}
`

const GC_UPDATE_JOURNEY_CONTACT = gql`
mutation UpdateJourneyContact($id: Int!, $contact_id: Int!, $contact: journey_contact_set_input!) {
	update_journey_contact_by_pk(pk_columns: {id: $id, contact_id: $contact_id}, _set: $contact) {
		id
	}
}
`

export {
	GC_GET_JOURNEY_LIST,
	GC_GET_JOURNEY_CONTACTS,
	GC_GET_JOURNEY_CONTACT_ANALYTICS,
	GC_UPDATE_JOURNEY,
	GC_DUPLICATE_JOURNEY,
	GC_ADD_JOURNEY_CONTACT,
	GC_UPDATE_JOURNEY_CONTACT,
}