<template>
  <div
    id="nav"
    class="bg-gray-background text-principal-gray p-4"
    @mouseover="open()"
    @mouseleave="close()"
  >
    <div class="my-4 logo-container h-12 m-auto">
      <div class="flex flex-row items-center">
        <img class="logo" src="@/assets/images/logo.svg" alt />
      </div>
    </div>
    <div class="nav-links w-full flex-grow" @click="toggleMobile">
      <router-link :to="{ name: 'dashboard' }" class="items-center navbar__nav-link">
        <div>
          <DashboardIcon />
          <span :class="isItemIsThePageWhereUserIs('dashboard') ? 'font-black' : ''">Tableau de bord</span>
        </div>
    </router-link>
      <router-link
        class="items-center navbar__nav-link"
        :to="{ name: 'users-list' }"
      >
        <div>
          <svg
            class="twn-icon fill-current"
            data-name="Calque 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
          >
            <defs />
            <path
              class="cls-1"
              d="M51.24,49.49A12.28,12.28,0,1,1,63.52,37.21,12.3,12.3,0,0,1,51.24,49.49Zm0-22.56A10.28,10.28,0,1,0,61.52,37.21,10.29,10.29,0,0,0,51.24,26.93Z"
            />
            <path
              class="cls-1"
              d="M68,72.47H66V64.73a8,8,0,0,0-8-8H44.47a8,8,0,0,0-8,8v7.74h-2V64.73a10,10,0,0,1,10-10H58a10,10,0,0,1,10,10Z"
            />
            <path
              class="cls-1"
              d="M77.07,53.38a9.49,9.49,0,1,1,9.49-9.49A9.5,9.5,0,0,1,77.07,53.38Zm0-17a7.49,7.49,0,1,0,7.49,7.49A7.5,7.5,0,0,0,77.07,36.4Z"
            />
            <path
              class="cls-1"
              d="M89,72.47a1,1,0,0,1-1-1V66.4a5.79,5.79,0,0,0-5.78-5.79H72a1,1,0,0,1,0-2H82.17A7.8,7.8,0,0,1,90,66.4v5.07A1,1,0,0,1,89,72.47Z"
            />
            <path
              class="cls-1"
              d="M25.06,53.38a9.49,9.49,0,1,1,9.48-9.49A9.5,9.5,0,0,1,25.06,53.38Zm0-17a7.49,7.49,0,1,0,7.48,7.49A7.5,7.5,0,0,0,25.06,36.4Z"
            />
            <path
              class="cls-1"
              d="M13.17,72.47a1,1,0,0,1-1-1V66.4A7.81,7.81,0,0,1,20,58.61H30.15a1,1,0,0,1,0,2H20a5.8,5.8,0,0,0-5.79,5.79v5.07A1,1,0,0,1,13.17,72.47Z"
            />
          </svg>
          <span
            :class="
              isItemIsThePageWhereUserIs('users-list') ? 'font-black' : ''
            "
            >Utilisateurs</span
          >
        </div>
      </router-link>
      <router-link
        v-if="isUserAdmin"
        class="items-center navbar__nav-link"
        :to="{ name: 'promotions-list' }"
      >
        <div>
          <svg
            class="twn-icon"
            width="24"
            height="17"
            viewBox="0 0 24 17"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
          >
            <circle
              cx="21.5"
              cy="10.5586"
              r="0.5"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M23.5 6.05859L12 0.558594L0.5 6.05859L12 11.5586L23.5 6.05859Z"
              stroke="currentColor"
              stroke-width="0.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 9.05859V12.5586C6.5 13.3919 8.4 15.0586 12 15.0586C15.6 15.0586 17.8333 13.3919 18.5 12.5586M21.5 9.55859V7.05859"
              stroke="currentColor"
              stroke-width="0.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span
            :class="
              isItemIsThePageWhereUserIs('promotions-list') ? 'font-black' : ''
            "
            >Promotions</span
          >
        </div>
      </router-link>
      <router-link
        v-if="isUserSuperAdmin"
        class="items-center navbar__nav-link"
        :to="{ name: 'customer-list' }"
      >
        <div>
          <svg
            class="twn-icon"
            width="24"
            height="20"
            viewBox="0 0 24 20"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
          >
            <path
              d="M0.5 19.0586H4M23.5 19.0586H20M4 19.0586V1.05859H13V6.05859M4 19.0586H13M13 19.0586V6.05859M13 19.0586H20M13 6.05859L20 8.55859V19.0586"
              stroke="currentColor"
              stroke-width="0.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span
            :class="
              isItemIsThePageWhereUserIs('customer-list') ? 'font-black' : ''
            "
            >Clients</span
          >
        </div>
      </router-link>
      <router-link
        v-if="isUserAdmin"
        class="items-center navbar__nav-link"
        :to="{ name: 'session-list' }"
      >
        <div>
          <svg
            width="50px"
            height="50px"
            version="1.1"
            viewBox="0 0 1200 1200"
            xmlns="http://www.w3.org/2000/svg"
            class="twn-icon"
          >
            <g fill="#2e3031">
              <path
                d="m1121.8 505.83c-4.6133-2.3906-10.188-1.9141-14.324 1.2266l-275.42 203.14v-142.09c-0.17188-3.918-1.8047-7.6289-4.5781-10.402-2.7695-2.7734-6.4844-4.4102-10.402-4.582h-732.29c-3.8633 0.19531-7.5039 1.8516-10.191 4.6328-2.6836 2.7852-4.207 6.4844-4.2617 10.352v478.36c0 7.7656 6.6875 12.914 14.453 12.914h732.29c7.7656 0 14.98-5.1562 14.98-12.914v-155.27l275.31 203.51c2.332 1.7812 5.1875 2.7461 8.1211 2.7461 2.2266 0 4.4219-0.51562 6.4141-1.5078 4.7578-2.3828 7.7773-7.2344 7.8086-12.555v-565.01c-0.078125-5.3359-3.1328-10.18-7.9141-12.551zm-317.87 525.42h-705.47v-450h705.47zm297.66 24.281-269.49-199.07-0.039062 0.41406v-111.75l269.53-198.93z"
              />
              <path
                d="m517.48 339.33c0 36.668 14.566 71.832 40.492 97.758 25.93 25.926 61.094 40.492 97.758 40.492 36.668 0 71.832-14.566 97.758-40.492 25.93-25.926 40.496-61.09 40.496-97.758s-14.566-71.832-40.496-97.758c-25.926-25.926-61.09-40.492-97.758-40.492-36.652 0.042969-71.793 14.621-97.711 40.539-25.918 25.918-40.496 61.059-40.539 97.711zm248.38 0h0.003906c0 29.207-11.605 57.219-32.258 77.871-20.652 20.652-48.664 32.254-77.871 32.254s-57.215-11.602-77.867-32.254c-20.656-20.652-32.258-48.664-32.258-77.871s11.602-57.219 32.258-77.871c20.652-20.652 48.66-32.254 77.867-32.254 29.199 0.03125 57.191 11.645 77.836 32.289 20.645 20.648 32.258 48.641 32.293 77.836z"
              />
              <path
                d="m86.293 287.79c0 49.129 19.516 96.242 54.254 130.98 34.742 34.738 81.855 54.254 130.98 54.254s96.246-19.516 130.99-54.254c34.738-34.742 54.254-81.855 54.254-130.98 0-49.129-19.516-96.246-54.254-130.99-34.742-34.738-81.859-54.254-130.99-54.254-49.109 0.054688-96.195 19.59-130.92 54.316-34.727 34.727-54.262 81.812-54.316 130.93zm342.36 0c0 41.668-16.555 81.633-46.02 111.1s-69.426 46.016-111.1 46.016c-41.668 0-81.633-16.551-111.1-46.016s-46.016-69.43-46.016-111.1c0-41.672 16.551-81.633 46.016-111.1 29.465-29.465 69.43-46.02 111.1-46.02 41.656 0.046875 81.59 16.617 111.05 46.07 29.453 29.457 46.023 69.391 46.07 111.05z"
              />
            </g>
          </svg>

          <span :class="isItemIsThePageWhereUserIs('visio') ? 'font-black' : ''"
            >Visio</span
          >
        </div>
      </router-link>
      <router-link to="/media/list" class="flex items-center navbar__nav-link">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="twn-icon fill-current p-1"
          viewBox="0 0 77.48 77.67"
        >
          <defs></defs>
          <path
            class="a"
            d="M43.74,17.14a1,1,0,0,0,1-1V7.76a1,1,0,1,0-2,0v8.38A1,1,0,0,0,43.74,17.14Z"
          />
          <path
            class="a"
            d="M49.19,21.6a1,1,0,0,0,1-1V3.3a1,1,0,1,0-2,0V20.6A1,1,0,0,0,49.19,21.6Z"
          />
          <path
            class="a"
            d="M54.65,17.14a1,1,0,0,0,1-1V7.76a1,1,0,1,0-2,0v8.38A1,1,0,0,0,54.65,17.14Z"
          />
          <path
            class="a"
            d="M60.11,21.6a1,1,0,0,0,1-1V3.3a1,1,0,1,0-2,0V20.6A1,1,0,0,0,60.11,21.6Z"
          />
          <path
            class="a"
            d="M65.56,23.9a1,1,0,0,0,1-1V1a1,1,0,0,0-2,0V22.9A1,1,0,0,0,65.56,23.9Z"
          />
          <path
            class="a"
            d="M71,2.3a1,1,0,0,0-1,1V20.6a1,1,0,1,0,2,0V3.3A1,1,0,0,0,71,2.3Z"
          />
          <path
            class="a"
            d="M76.48,6.76a1,1,0,0,0-1,1v8.38a1,1,0,0,0,2,0V7.76A1,1,0,0,0,76.48,6.76Z"
          />
          <path
            class="a"
            d="M25.77,34.63a1,1,0,0,0-.5-.87L1.5,20.08a1,1,0,0,0-1,0A1,1,0,0,0,0,21V48.3a1,1,0,0,0,.5.87,1,1,0,0,0,.5.13,1,1,0,0,0,.5-.13L25.27,35.5A1,1,0,0,0,25.77,34.63ZM2,46.58V22.68l20.77,12Z"
          />
          <path
            class="a"
            d="M70.8,51.48H36.22a1,1,0,0,0-1,1V76.67a1,1,0,0,0,1,1H70.8a1,1,0,0,0,1-1V52.48A1,1,0,0,0,70.8,51.48Zm-1,24.19H37.22V67.25l7.34-7.34,7.67,7.67a1,1,0,0,0,1.41,0L56.24,65l7.19,7.19a1,1,0,0,0,1.41,0l1.46-1.46,3.5,3.5Zm0-4.27L67,68.6a1,1,0,0,0-1.41,0l-1.46,1.45-7.19-7.18a1,1,0,0,0-1.41,0l-2.6,2.59-7.66-7.67a1,1,0,0,0-1.42,0l-6.63,6.63V53.48H69.8Z"
          />
          <path
            class="a"
            d="M64.12,63.64a4.3,4.3,0,1,0-4.3-4.3A4.3,4.3,0,0,0,64.12,63.64Zm0-6.6a2.3,2.3,0,1,1-2.3,2.3A2.3,2.3,0,0,1,64.12,57Z"
          />
          <path
            class="a"
            d="M28.76,63.28A26.3,26.3,0,0,1,14.69,49.73a1,1,0,0,0-1.82.83A28.35,28.35,0,0,0,28,65.13a1,1,0,0,0,.37.07,1,1,0,0,0,.38-1.92Z"
          />
          <path
            class="a"
            d="M65.13,38.83a26.34,26.34,0,0,1-1,7.14,1,1,0,0,0,.69,1.23,1.26,1.26,0,0,0,.27,0,1,1,0,0,0,1-.73,28.74,28.74,0,0,0,1-7.68,28.17,28.17,0,0,0-2-10.45,1,1,0,0,0-1.86.73A26.43,26.43,0,0,1,65.13,38.83Z"
          />
          <path
            class="a"
            d="M17.75,22.22a1,1,0,0,0,.77-.35,26.3,26.3,0,0,1,17.07-9.25,1,1,0,1,0-.23-2A28.25,28.25,0,0,0,17,20.58,1,1,0,0,0,17.11,22,1,1,0,0,0,17.75,22.22Z"
          />
        </svg>
        <span>Médias</span>
      </router-link>

      <hr />
      <div v-if="true" class="flex flex-col">
        <div
          @click="onItemClick('medias')"
          @mouseover="openedItem = 'medias'"
          class="navbar__nav-link flex items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="twn-icon fill-current"
            viewBox="0 0 80.73 80.73"
          >
            <defs></defs>
            <path
              class="a"
              d="M15.62,9.52a6.1,6.1,0,1,0,6.09,6.1A6.11,6.11,0,0,0,15.62,9.52Zm0,10.19a4.1,4.1,0,1,1,4.09-4.09A4.1,4.1,0,0,1,15.62,19.71Z"
            />
            <path
              class="a"
              d="M19.41,30.23V27.85a13.77,13.77,0,0,0,2.17-.9l1.69,1.68a1,1,0,0,0,1.41,0l3.95-3.95a1,1,0,0,0,0-1.41L27,21.58a12.11,12.11,0,0,0,.9-2.17h2.38a1,1,0,0,0,1-1V12.82a1,1,0,0,0-1-1H27.85A12.11,12.11,0,0,0,27,9.65L28.63,8a1,1,0,0,0,0-1.41L24.68,2.6a1,1,0,0,0-1.41,0L21.59,4.28a12.51,12.51,0,0,0-2.18-.9V1a1,1,0,0,0-1-1H12.82a1,1,0,0,0-1,1V3.38a12.11,12.11,0,0,0-2.17.9L8,2.6a1,1,0,0,0-1.41,0l-3.95,4A1,1,0,0,0,2.6,8L4.28,9.65a13.77,13.77,0,0,0-.9,2.17H1a1,1,0,0,0-1,1v5.59a1,1,0,0,0,1,1H3.39a12.85,12.85,0,0,0,.89,2.17L2.6,23.27a1,1,0,0,0,0,1.41l4,3.95a1,1,0,0,0,1.41,0L9.65,27a13.77,13.77,0,0,0,2.17.9v2.38a1,1,0,0,0,1,1h5.59A1,1,0,0,0,19.41,30.23Zm-2-3.15v2.15H13.82V27.08a1,1,0,0,0-.76-1A10.92,10.92,0,0,1,10,24.85,1,1,0,0,0,8.78,25L7.26,26.51,4.72,24l1.52-1.51a1,1,0,0,0,.14-1.23,10.8,10.8,0,0,1-1.26-3.06,1,1,0,0,0-1-.76H2V13.82H4.14a1,1,0,0,0,1-.76A10.52,10.52,0,0,1,6.38,10a1,1,0,0,0-.15-1.23L4.72,7.26,7.26,4.72,8.77,6.23A1,1,0,0,0,10,6.38a11,11,0,0,1,3.06-1.27,1,1,0,0,0,.76-1V2h3.59V4.14a1,1,0,0,0,.77,1,10.77,10.77,0,0,1,3.05,1.27,1,1,0,0,0,1.23-.15L24,4.72l2.54,2.54L25,8.77A1,1,0,0,0,24.85,10a10.43,10.43,0,0,1,1.27,3.06,1,1,0,0,0,1,.76h2.14v3.59H27.09a1,1,0,0,0-1,.76,10.71,10.71,0,0,1-1.27,3.06A1,1,0,0,0,25,22.46L26.51,24,24,26.51,22.46,25a1,1,0,0,0-1.23-.15,10.68,10.68,0,0,1-3.06,1.26A1,1,0,0,0,17.41,27.08Z"
            />
            <path
              class="a"
              d="M69,69.14V1.06a1,1,0,0,0-1-1H26.29a1,1,0,0,0-1,1,1,1,0,0,0,1,1H67V68.14H16.62v-34a1,1,0,0,0-2,0v35a1,1,0,0,0,1,1H68A1,1,0,0,0,69,69.14Z"
            />
            <path
              class="a"
              d="M79.73,10.65h-6a1,1,0,0,0,0,2h5V78.73H28.35v-4.2a1,1,0,0,0-2,0v5.2a1,1,0,0,0,1,1H79.73a1,1,0,0,0,1-1V11.65A1,1,0,0,0,79.73,10.65Z"
            />
            <path
              class="a"
              d="M38.08,13.82H59a1,1,0,0,0,0-2H38.08a1,1,0,0,0,0,2Z"
            />
            <path
              class="a"
              d="M38.08,25H59a1,1,0,0,0,0-2H38.08a1,1,0,0,0,0,2Z"
            />
            <path
              class="a"
              d="M23,35.23a1,1,0,0,0,1,1H59a1,1,0,0,0,0-2H24A1,1,0,0,0,23,35.23Z"
            />
            <path class="a" d="M59,45.43H24a1,1,0,0,0,0,2H59a1,1,0,0,0,0-2Z" />
          </svg>
          <span>Gestion des contenus</span>
        </div>

        <transition name="fade-x">
          <div
            v-if="openedItem === 'medias'"
            class="flex flex-col navbar__nav-link-children"
          >
            <router-link
              to="/games/list"
              class="flex items-center navbar__nav-link"
            >
              <svg
                class="twn-icon fill-current"
                data-name="Calque 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
              >
                <defs></defs>
                <path
                  class="cls-1"
                  d="M50,57a7,7,0,1,0-7-7A7,7,0,0,0,50,57Zm0-12a5,5,0,1,1-5,5A5,5,0,0,1,50,45Z"
                />
                <path
                  class="cls-1"
                  d="M69.32,30.68a1,1,0,0,0,.7.3,1,1,0,0,0,.71-.3L76,25.41l5.27,5.27A1,1,0,0,0,82,31a1,1,0,0,0,.7-1.71L77.41,24l5.27-5.27a1,1,0,0,0-1.41-1.41L76,22.59l-5.27-5.27a1,1,0,0,0-1.41,1.41L74.59,24l-5.27,5.27A1,1,0,0,0,69.32,30.68Z"
                />
                <path
                  class="cls-1"
                  d="M30.68,69.32a1,1,0,0,0-1.41,0L24,74.59l-5.27-5.27a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.41L22.59,76l-5.27,5.27a1,1,0,0,0,0,1.41,1,1,0,0,0,.7.3,1,1,0,0,0,.71-.3L24,77.41l5.27,5.27A1,1,0,0,0,30,83a1,1,0,0,0,.7-.3,1,1,0,0,0,0-1.41L25.41,76l5.27-5.27A1,1,0,0,0,30.68,69.32Z"
                />
                <path
                  class="cls-1"
                  d="M85.49,62H64V38H85.49a1,1,0,0,0,0-2H64V14.51a1,1,0,0,0-2,0V36H38V14.51a1,1,0,1,0-2,0V36H14.51a1,1,0,1,0,0,2H36V62H14.51a1,1,0,0,0,0,2H36V85.49a1,1,0,0,0,2,0V64H62V85.49a1,1,0,1,0,2,0V64H85.49a1,1,0,1,0,0-2ZM62,62H38V38H62Z"
                />
              </svg>
              <span>Mini-jeux</span>
            </router-link>
          </div>
        </transition>
      </div>
    </div>

    <div
      @click="toggleNav"
      class="arrows bootstrap md:visible md:block text-center cursor-pointer w-full"
    >
      <b-icon-arrow-up class="hidden h1 up-arrow m-auto"></b-icon-arrow-up>
      <b-icon-arrow-right class="right-arrow h1 m-0"></b-icon-arrow-right>
      <div class="left-arrow hidden justify-between items-center">
        <span
          class="uppercase ml-auto mr-auto font-semibold opacity-75 ease-linear"
          >Réduire</span
        >
        <b-icon-arrow-left class="left-arrow h1 mr-3"></b-icon-arrow-left>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import tailwindConf from "../../tailwind.config";
import DashboardIcon from '@/components/icons/DashboardIcon'

export default {
  name: "Navbar",
  components: {
    DashboardIcon
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      publicPath: process.env.BASE_URL,
      openedItem: null,
      staticCourses: {
        mission: null,
        investigation: null,
      },
    };
  },
  computed: {
    ...mapState("Auth", ["userInfo"]),
    isUserAdmin() {
      return (
        this.userInfo &&
        (this.userInfo.role == "superadmin" ||
          this.userInfo.role == "customer_manager")
      );
    },
    isUserSuperAdmin() {
      return this.userInfo && this.userInfo.role == "superadmin";
    },
  },
  methods: {
    ...mapActions("Header", ["toggleNav", "openNav", "closeNav"]),
    toggleMobile(e) {
      if (this.window.width <= parseInt(tailwindConf.theme.screens.sm, 10)) {
        this.toggleNav(e);
      }
    },
    open() {
      this.openNav();
    },
    close() {
      this.closeNav();
      this.openedItem = null;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    onItemClick(itemName) {
      this.openedItem = this.openedItem === itemName ? null : itemName;
    },
    isItemIsThePageWhereUserIs(item) {
      return this.$route.matched.some(({ name }) => name === item);
    },
  },
  async created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss">
#nav {
  @apply border-line-left left-0 right-auto overflow-y-auto flex flex-col items-center;
  border-right-width: 1px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.3s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transform: translate3d(0px, -100vh, 0) !important;
  overflow-x: hidden;
  // width: $navbarLength;
  width: 100%;
  z-index: 99;

  > .arrows {
    @apply block;
    .up-arrow {
      @apply block;
    }
    .right-arrow {
      @apply hidden;
    }
  }

  .logo {
    @apply w-full;
    max-width: 5rem;
  }

  &.nav-open {
    transform: translate3d(0px, 0, 0) !important;
    .logo {
      transition: all 0.1s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
  }

  > div:not(.arrows) {
    @apply flex flex-col;

    &.logo-container {
      @apply self-start;
    }
    &.nav-links {
      @apply text-xs tracking-tighter leading-loose;
      opacity: 0.9;

      .navbar__nav-link {
        div {
          @apply flex items-center;
        }
        &:hover {
          @apply text-principal-selected no-underline cursor-pointer;
        }
      }

      div,
      a {
        .twn-icon {
          @apply mb-0 transition duration-100 ease-linear;
          width: 40px;
          height: 40px;
        }
        span {
          @apply transition duration-100 ease-linear tracking-normal text-base absolute;
          @screen md {
            @apply text-sm;
          }
          left: 70px;
        }
        .navbar__nav-link-children {
          a {
            @apply mt-4;
          }
          span {
            @apply text-xs;
          }
        }
      }

      > a {
        @apply py-2 px-1 rounded;
      }

      > hr {
        @apply my-3;
      }
    }
  }

  @screen md {
    @apply flex flex-col;
    overflow-y: none;
    width: $navbarLength;

    transform: translate3d(0px, 0, 0) !important;

    .arrows {
      .up-arrow {
        @apply hidden;
      }
    }
    .right-arrow {
      @apply hidden m-0;
    }
    .left-arrow {
      @apply flex visible m-0;
    }
    .nav-links {
      span {
        @apply opacity-100;
      }
    }
    &:not(.nav-open) {
      // @apply justify-between;
      // .arrows{
      //     @apply mt-0;
      // }
      .left-arrow {
        @apply hidden;
      }
      .right-arrow {
        @apply block visible;
      }
      width: 5rem !important;
      // .logo{
      //     @apply w-full;
      // }
      .nav-links {
        // .twn-icon {
        //     margin: 0 !important;
        // }
        span {
          @apply opacity-0;

          // @apply hidden;
        }
      }
    }
  }
}
</style>
