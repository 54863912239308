<template>
    <div class="organisation-detail mt-8 md:mt-0">
        <div class="buttons flex flex-row justify-around md:justify-between">
            <button type="button" class="twn-button text-xs" @click="goBack">Retour</button>
            <button type="button" class="twn-button text-xs danger" @click="showDeleteModal(data.item.id)" v-if="!creating">Supprimer le profil</button>
        </div>
        <div class="flex flex-col">
            <div class="twn-card organisation-card my-10">
                <div class="uppercase font-principal-bold text-sm" v-if="creating">Ajouter un profil</div>
                <div class="uppercase font-principal-bold text-sm" v-else>éditer le profil</div>
                <div class="stars flex flex-row justify-end">
                    <svg v-for="n in star.number" class="star" :key="n" v-bind:class="n <= star.colored ? 'complete': '' " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.18 64.59"><defs></defs><title>Fichier 302</title><g id="Calque_2" data-name="Calque 2"><g id="Calque_1-2" data-name="Calque 1"><path class="cls-1" d="M64.8,32.09A7.92,7.92,0,0,0,65,21a10.5,10.5,0,0,0-4.57-2.41l-12-1.68A3.45,3.45,0,0,1,46,15.24L40.73,4.41h0A8,8,0,0,0,30.14.8a7.53,7.53,0,0,0-3.61,3.61l-5.3,10.83a3.45,3.45,0,0,1-2.4,1.69l-12,1.68A8,8,0,0,0,.05,27.52a7.16,7.16,0,0,0,2.41,4.57l8.66,8.43a3.4,3.4,0,0,1,.73,2.64l-1.93,12a7.93,7.93,0,0,0,6.5,9.15,9.2,9.2,0,0,0,5-.73l10.84-5.77a3.08,3.08,0,0,1,2.89,0L46,63.62a9.87,9.87,0,0,0,3.61,1,8,8,0,0,0,7.94-8V55.2l-2.41-12a2.84,2.84,0,0,1,1-2.64Zm-12,5.06a7.54,7.54,0,0,0-2.17,7l2.17,12a3.06,3.06,0,0,1-2.41,3.61,2.47,2.47,0,0,1-2.17-.48L37.36,53.51a7.69,7.69,0,0,0-7.22,0L19.07,59.29A3.08,3.08,0,0,1,15,58.09a2.68,2.68,0,0,1-.24-1.93l2.16-12a8.23,8.23,0,0,0-2.16-7L5.59,28.72a3,3,0,0,1,0-4.33,6,6,0,0,1,1.68-1l12-1.69a8.06,8.06,0,0,0,6-4.33L30.62,6.58a3.17,3.17,0,0,1,4.09-1.45,4,4,0,0,1,1.45,1.45l5.29,10.83a7.81,7.81,0,0,0,6,4.09l12,1.69a3.37,3.37,0,0,1,2.88,3.37,2.52,2.52,0,0,1-1.2,2.16Z"/></g></g></svg>
                </div>
                <hr>
                <form class="flex flex-row flex-wrap justify-around" @submit="createOrEdit">
                    <div class="left">
                        <div class="input-group mt-10">
                            <label for="name">Nom</label>
                            <b-form-input v-model="fields.name" type="text" id="name" :state="nameValidation"></b-form-input>
                        </div>
                        <div class="input-group mt-10">
                            <label for="owner">Gérant</label>
                            <v-select
                                v-model="fields.admin_id"
                                id="owner"
                                :options="userList"
                                placeholder="Utilisateur"
                                class="twn-select"
                                label="name"
                                :reduce="user => user.id"
                            >
                            </v-select>
                        </div>
                        <div class="input-group input-group--start">
                            <label>HLM</label>
                            <div>
                                <Toggle
                                    :checked="fields.hlm"
                                    :onInput="($event) => fields.hlm = $event"
                                    class="mr-3"
                                />
                            </div>
                        </div>
                        <div class="input-group">
                            <label>Nombre de logements</label>
                            <b-form-input v-model="fields.housing" type="number"></b-form-input>
                        </div>

                        <div class="input-group">
                            <label>Rattachement à une fédération</label>
                            <v-select
                                v-model="fields.federation_id"
                                :options="federationList"
                                label="name"
                                :reduce="fed => fed.id"
                                placeholder="Sélectionner une fédération..."
                                class="twn-select"
                            ></v-select>
                        </div>
                    </div>
                    <div class="right">
                        <div class="input-group mt-0 md:mt-10">
                            <label for="address">Adresse</label>
                            <b-form-input v-model="fields.address" type="text" id="address" :state="addressValidation"></b-form-input>
                        </div>
                        <div class="input-group">
                            <label for="address2">Adresse 2</label>
                            <b-form-input v-model="fields.address2" type="text" id="address2"></b-form-input>
                        </div>
                        <div class="input-group town-group">
                            <label for="city">Ville</label>
                            <b-form-input v-model="fields.city" type="text" id="city" :state="cityValidation"></b-form-input>
                            <v-select
                                v-model="fields.country"
                                :options="countryList"
                                placeholder="Pays"
                                label="name"
                                class="twn-select"
                                v-bind:class="{'is-invalid':!this.countryValidation}"
                                :reduce="country => country.code"
                            >
                                <template #search="{attributes, events}">
                                    <input
                                    class="vs__search"
                                    :required="!countryValidation"
                                    v-bind="attributes"
                                    v-on="events"
                                    />
                                </template>
                            </v-select>
                        </div>
                        <div class="input-group">
                            <label for="post_code">Code Postal</label>
                            <b-form-input v-model="fields.post_code" type="text" pattern="[0-9]*" id="post_code" :state="codeValidation"></b-form-input>
                        </div>
                    </div>

                    <PromotionList class="w-full" :pageSize="5" />

                    <div class="ml-auto mt-4">
                        <div class="button-holder mb-0">
                            <button type="submit" class="twn-button text-sm" v-if="creating">Ajouter le profil</button>
                            <button type="submit" class="twn-button text-sm" v-else>Mettre à jour le profil</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="twn-card my-10" v-if="getOrganization.id">
                <p class="font-principal-bold text-sm mb-8 uppercase">Statistiques pédagogiques de l'office</p>
            </div>
        </div>
        <b-modal ref="delete-modal-detail" class="bootstrap" centered hide-footer id="delete-modal-detail" hide-header>
            <div class="d-block text-center my-6 uppercase font-semibold">
                <h3>Confirmer la suppression</h3>
            </div>
            <div class="flex flex-row justify-evenly items-center">
                <button type="button" class="mt-4 twn-button" @click="$bvModal.hide('delete-modal-detail')">Retour</button>
                <button type="button" class="mt-4 twn-button danger" @click="deleteItem">Supprimer</button>
            </div>
        </b-modal>
    </div>
</template>
<script>

import { mapGetters, mapActions, mapState } from 'vuex';
import { FC_DELETE_ITEM } from '@/graphql/list'
import router from '@/router/index'
import Toggle from '@/components/Toggle'
import PromotionList from '@/views/Promotions/PromotionList'

export default {
    name: 'OrganisationDetail',
    components: {
        Toggle,
        PromotionList
    },
    props: {
        itemID: {
            type: String,
            required: false
        },
        previousPage: {
            type: Number,
            required: false
        }
    },
    async mounted() {
        if (this.getCurrentID !== -1 || this.itemID){
            let id = this.itemID;
            if (this.getCurrentID !== -1 && !this.itemID){
                id = this.getCurrentID;
            }
            await this.setOrganization(id)
            this.mapFields();
        } else {
            this.creating = true;
        }
        await this.initOrgaEditLists();
        this.userList = this.getUserList;
    },
    data() {
        return {
            star: {
                number: 8,
                colored: 2
            },
            selectedID: '',
            countryList: [ 
                {"name": "Afghanistan", "code": "AF"}, 
                {"name": "Aland Islands", "code": "AX"}, 
                {"name": "Albania", "code": "AL"}, 
                {"name": "Algeria", "code": "DZ"}, 
                {"name": "American Samoa", "code": "AS"}, 
                {"name": "Andorra", "code": "AD"}, 
                {"name": "Angola", "code": "AO"}, 
                {"name": "Anguilla", "code": "AI"}, 
                {"name": "Antarctica", "code": "AQ"}, 
                {"name": "Antigua and Barbuda", "code": "AG"}, 
                {"name": "Argentina", "code": "AR"}, 
                {"name": "Armenia", "code": "AM"}, 
                {"name": "Aruba", "code": "AW"}, 
                {"name": "Australia", "code": "AU"}, 
                {"name": "Austria", "code": "AT"}, 
                {"name": "Azerbaijan", "code": "AZ"}, 
                {"name": "Bahamas", "code": "BS"}, 
                {"name": "Bahrain", "code": "BH"}, 
                {"name": "Bangladesh", "code": "BD"}, 
                {"name": "Barbados", "code": "BB"}, 
                {"name": "Belarus", "code": "BY"}, 
                {"name": "Belgium", "code": "BE"}, 
                {"name": "Belize", "code": "BZ"}, 
                {"name": "Benin", "code": "BJ"}, 
                {"name": "Bermuda", "code": "BM"}, 
                {"name": "Bhutan", "code": "BT"}, 
                {"name": "Bolivia", "code": "BO"}, 
                {"name": "Bosnia and Herzegovina", "code": "BA"}, 
                {"name": "Botswana", "code": "BW"}, 
                {"name": "Bouvet Island", "code": "BV"}, 
                {"name": "Brazil", "code": "BR"}, 
                {"name": "British Indian Ocean Territory", "code": "IO"}, 
                {"name": "Brunei Darussalam", "code": "BN"}, 
                {"name": "Bulgaria", "code": "BG"}, 
                {"name": "Burkina Faso", "code": "BF"}, 
                {"name": "Burundi", "code": "BI"}, 
                {"name": "Cambodia", "code": "KH"}, 
                {"name": "Cameroon", "code": "CM"}, 
                {"name": "Canada", "code": "CA"}, 
                {"name": "Cape Verde", "code": "CV"}, 
                {"name": "Cayman Islands", "code": "KY"}, 
                {"name": "Central African Republic", "code": "CF"}, 
                {"name": "Chad", "code": "TD"}, 
                {"name": "Chile", "code": "CL"}, 
                {"name": "China", "code": "CN"}, 
                {"name": "Christmas Island", "code": "CX"}, 
                {"name": "Cocos (Keeling) Islands", "code": "CC"}, 
                {"name": "Colombia", "code": "CO"}, 
                {"name": "Comoros", "code": "KM"}, 
                {"name": "Congo", "code": "CG"}, 
                {"name": "Congo, The Democratic Republic of the", "code": "CD"}, 
                {"name": "Cook Islands", "code": "CK"}, 
                {"name": "Costa Rica", "code": "CR"}, 
                {"name": "Cote D'Ivoire", "code": "CI"}, 
                {"name": "Croatia", "code": "HR"}, 
                {"name": "Cuba", "code": "CU"}, 
                {"name": "Cyprus", "code": "CY"}, 
                {"name": "Czech Republic", "code": "CZ"}, 
                {"name": "Denmark", "code": "DK"}, 
                {"name": "Djibouti", "code": "DJ"}, 
                {"name": "Dominica", "code": "DM"}, 
                {"name": "Dominican Republic", "code": "DO"}, 
                {"name": "Ecuador", "code": "EC"}, 
                {"name": "Egypt", "code": "EG"}, 
                {"name": "El Salvador", "code": "SV"}, 
                {"name": "Equatorial Guinea", "code": "GQ"}, 
                {"name": "Eritrea", "code": "ER"}, 
                {"name": "Estonia", "code": "EE"}, 
                {"name": "Ethiopia", "code": "ET"}, 
                {"name": "Falkland Islands (Malvinas)", "code": "FK"}, 
                {"name": "Faroe Islands", "code": "FO"}, 
                {"name": "Fiji", "code": "FJ"}, 
                {"name": "Finland", "code": "FI"}, 
                {"name": "France", "code": "FR"}, 
                {"name": "French Guiana", "code": "GF"}, 
                {"name": "French Polynesia", "code": "PF"}, 
                {"name": "French Southern Territories", "code": "TF"}, 
                {"name": "Gabon", "code": "GA"}, 
                {"name": "Gambia", "code": "GM"}, 
                {"name": "Georgia", "code": "GE"}, 
                {"name": "Germany", "code": "DE"}, 
                {"name": "Ghana", "code": "GH"}, 
                {"name": "Gibraltar", "code": "GI"}, 
                {"name": "Greece", "code": "GR"}, 
                {"name": "Greenland", "code": "GL"}, 
                {"name": "Grenada", "code": "GD"}, 
                {"name": "Guadeloupe", "code": "GP"}, 
                {"name": "Guam", "code": "GU"}, 
                {"name": "Guatemala", "code": "GT"}, 
                {"name": "Guernsey", "code": "GG"}, 
                {"name": "Guinea", "code": "GN"}, 
                {"name": "Guinea-Bissau", "code": "GW"}, 
                {"name": "Guyana", "code": "GY"}, 
                {"name": "Haiti", "code": "HT"}, 
                {"name": "Heard Island and Mcdonald Islands", "code": "HM"}, 
                {"name": "Holy See (Vatican City State)", "code": "VA"}, 
                {"name": "Honduras", "code": "HN"}, 
                {"name": "Hong Kong", "code": "HK"}, 
                {"name": "Hungary", "code": "HU"}, 
                {"name": "Iceland", "code": "IS"}, 
                {"name": "India", "code": "IN"}, 
                {"name": "Indonesia", "code": "ID"}, 
                {"name": "Iran, Islamic Republic Of", "code": "IR"}, 
                {"name": "Iraq", "code": "IQ"}, 
                {"name": "Ireland", "code": "IE"}, 
                {"name": "Isle of Man", "code": "IM"}, 
                {"name": "Israel", "code": "IL"}, 
                {"name": "Italy", "code": "IT"}, 
                {"name": "Jamaica", "code": "JM"}, 
                {"name": "Japan", "code": "JP"}, 
                {"name": "Jersey", "code": "JE"}, 
                {"name": "Jordan", "code": "JO"}, 
                {"name": "Kazakhstan", "code": "KZ"}, 
                {"name": "Kenya", "code": "KE"}, 
                {"name": "Kiribati", "code": "KI"}, 
                {"name": "Korea, Democratic People'S Republic of", "code": "KP"}, 
                {"name": "Korea, Republic of", "code": "KR"}, 
                {"name": "Kuwait", "code": "KW"}, 
                {"name": "Kyrgyzstan", "code": "KG"}, 
                {"name": "Lao People'S Democratic Republic", "code": "LA"}, 
                {"name": "Latvia", "code": "LV"}, 
                {"name": "Lebanon", "code": "LB"}, 
                {"name": "Lesotho", "code": "LS"}, 
                {"name": "Liberia", "code": "LR"}, 
                {"name": "Libyan Arab Jamahiriya", "code": "LY"}, 
                {"name": "Liechtenstein", "code": "LI"}, 
                {"name": "Lithuania", "code": "LT"}, 
                {"name": "Luxembourg", "code": "LU"}, 
                {"name": "Macao", "code": "MO"}, 
                {"name": "Macedonia, The Former Yugoslav Republic of", "code": "MK"}, 
                {"name": "Madagascar", "code": "MG"}, 
                {"name": "Malawi", "code": "MW"}, 
                {"name": "Malaysia", "code": "MY"}, 
                {"name": "Maldives", "code": "MV"}, 
                {"name": "Mali", "code": "ML"}, 
                {"name": "Malta", "code": "MT"}, 
                {"name": "Marshall Islands", "code": "MH"}, 
                {"name": "Martinique", "code": "MQ"}, 
                {"name": "Mauritania", "code": "MR"}, 
                {"name": "Mauritius", "code": "MU"}, 
                {"name": "Mayotte", "code": "YT"}, 
                {"name": "Mexico", "code": "MX"}, 
                {"name": "Micronesia, Federated States of", "code": "FM"}, 
                {"name": "Moldova, Republic of", "code": "MD"}, 
                {"name": "Monaco", "code": "MC"}, 
                {"name": "Mongolia", "code": "MN"}, 
                {"name": "Montenegro", "code": "ME"},
                {"name": "Montserrat", "code": "MS"},
                {"name": "Morocco", "code": "MA"}, 
                {"name": "Mozambique", "code": "MZ"}, 
                {"name": "Myanmar", "code": "MM"}, 
                {"name": "Namibia", "code": "NA"}, 
                {"name": "Nauru", "code": "NR"}, 
                {"name": "Nepal", "code": "NP"}, 
                {"name": "Netherlands", "code": "NL"}, 
                {"name": "Netherlands Antilles", "code": "AN"}, 
                {"name": "New Caledonia", "code": "NC"}, 
                {"name": "New Zealand", "code": "NZ"}, 
                {"name": "Nicaragua", "code": "NI"}, 
                {"name": "Niger", "code": "NE"}, 
                {"name": "Nigeria", "code": "NG"}, 
                {"name": "Niue", "code": "NU"}, 
                {"name": "Norfolk Island", "code": "NF"}, 
                {"name": "Northern Mariana Islands", "code": "MP"}, 
                {"name": "Norway", "code": "NO"}, 
                {"name": "Oman", "code": "OM"}, 
                {"name": "Pakistan", "code": "PK"}, 
                {"name": "Palau", "code": "PW"}, 
                {"name": "Palestinian Territory, Occupied", "code": "PS"}, 
                {"name": "Panama", "code": "PA"}, 
                {"name": "Papua New Guinea", "code": "PG"}, 
                {"name": "Paraguay", "code": "PY"}, 
                {"name": "Peru", "code": "PE"}, 
                {"name": "Philippines", "code": "PH"}, 
                {"name": "Pitcairn", "code": "PN"}, 
                {"name": "Poland", "code": "PL"}, 
                {"name": "Portugal", "code": "PT"}, 
                {"name": "Puerto Rico", "code": "PR"}, 
                {"name": "Qatar", "code": "QA"}, 
                {"name": "Reunion", "code": "RE"}, 
                {"name": "Romania", "code": "RO"}, 
                {"name": "Russian Federation", "code": "RU"}, 
                {"name": "RWANDA", "code": "RW"}, 
                {"name": "Saint Helena", "code": "SH"}, 
                {"name": "Saint Kitts and Nevis", "code": "KN"}, 
                {"name": "Saint Lucia", "code": "LC"}, 
                {"name": "Saint Pierre and Miquelon", "code": "PM"}, 
                {"name": "Saint Vincent and the Grenadines", "code": "VC"}, 
                {"name": "Samoa", "code": "WS"}, 
                {"name": "San Marino", "code": "SM"}, 
                {"name": "Sao Tome and Principe", "code": "ST"}, 
                {"name": "Saudi Arabia", "code": "SA"}, 
                {"name": "Senegal", "code": "SN"}, 
                {"name": "Serbia", "code": "RS"}, 
                {"name": "Seychelles", "code": "SC"}, 
                {"name": "Sierra Leone", "code": "SL"}, 
                {"name": "Singapore", "code": "SG"}, 
                {"name": "Slovakia", "code": "SK"}, 
                {"name": "Slovenia", "code": "SI"}, 
                {"name": "Solomon Islands", "code": "SB"}, 
                {"name": "Somalia", "code": "SO"}, 
                {"name": "South Africa", "code": "ZA"}, 
                {"name": "South Georgia and the South Sandwich Islands", "code": "GS"}, 
                {"name": "Spain", "code": "ES"}, 
                {"name": "Sri Lanka", "code": "LK"}, 
                {"name": "Sudan", "code": "SD"}, 
                {"name": "Suriname", "code": "SR"}, 
                {"name": "Svalbard and Jan Mayen", "code": "SJ"}, 
                {"name": "Swaziland", "code": "SZ"}, 
                {"name": "Sweden", "code": "SE"}, 
                {"name": "Switzerland", "code": "CH"}, 
                {"name": "Syrian Arab Republic", "code": "SY"}, 
                {"name": "Taiwan, Province of China", "code": "TW"}, 
                {"name": "Tajikistan", "code": "TJ"}, 
                {"name": "Tanzania, United Republic of", "code": "TZ"}, 
                {"name": "Thailand", "code": "TH"}, 
                {"name": "Timor-Leste", "code": "TL"}, 
                {"name": "Togo", "code": "TG"}, 
                {"name": "Tokelau", "code": "TK"}, 
                {"name": "Tonga", "code": "TO"}, 
                {"name": "Trinidad and Tobago", "code": "TT"}, 
                {"name": "Tunisia", "code": "TN"}, 
                {"name": "Turkey", "code": "TR"}, 
                {"name": "Turkmenistan", "code": "TM"}, 
                {"name": "Turks and Caicos Islands", "code": "TC"}, 
                {"name": "Tuvalu", "code": "TV"}, 
                {"name": "Uganda", "code": "UG"}, 
                {"name": "Ukraine", "code": "UA"}, 
                {"name": "United Arab Emirates", "code": "AE"}, 
                {"name": "United Kingdom", "code": "GB"}, 
                {"name": "United States", "code": "US"}, 
                {"name": "United States Minor Outlying Islands", "code": "UM"}, 
                {"name": "Uruguay", "code": "UY"}, 
                {"name": "Uzbekistan", "code": "UZ"}, 
                {"name": "Vanuatu", "code": "VU"}, 
                {"name": "Venezuela", "code": "VE"}, 
                {"name": "Viet Nam", "code": "VN"}, 
                {"name": "Virgin Islands, British", "code": "VG"}, 
                {"name": "Virgin Islands, U.S.", "code": "VI"}, 
                {"name": "Wallis and Futuna", "code": "WF"}, 
                {"name": "Western Sahara", "code": "EH"}, 
                {"name": "Yemen", "code": "YE"}, 
                {"name": "Zambia", "code": "ZM"}, 
                {"name": "Zimbabwe", "code": "ZW"} 
                ],
            fields: {
                id: '',
                name: '',
                address: '',
                address2: '',
                city: '',
                hml: false,
                housing: 0,
                federation: null,
                post_code: '',
                country: '',
                admin_id: null
            },
            delayer: true,
            creating: false,
            userList: [],
            dashboardFilter: null,
            dashboardFilterList: [
                {
                    id: 0,
                    label: 'Période',
                },
                {
                    id: 1,
                    label: 'Utilisateur',
                },
                {
                    id: 2,
                    label: 'Ancienneté',
                },
            ],
        }
    },
    computed: {
        ...mapState('EditItem', ['federationList']),
        ...mapGetters('EditItem', ['getOrganization', 'getCountryList', 'getCurrentID', 'getUserList']),
        nameValidation(){
            return this.fields.name !== undefined && this.fields.name !== null && this.fields.name !== '';
        },
        addressValidation(){
            return this.fields.address !== undefined && this.fields.address !== null && this.fields.address !== '';
        },
        cityValidation(){
            return this.fields.city !== undefined && this.fields.city !== null && this.fields.city !== '';
        },
        codeValidation(){
            if (this.fields.post_code !== undefined && this.fields.post_code !== null && this.fields.post_code.toString().length >= 5){
                let n = this.fields.post_code;
                if (+n === +n) {
                    return true;
                }
            }
            return false;
        },
        countryValidation(){
            return this.fields.country !== undefined && this.fields.country !== null && this.fields.country !== '';
        },
        allValid(){
            return this.nameValidation && this.addressValidation && this.cityValidation
                && this.codeValidation && this.countryValidation;
        }
    },
    methods: {
        ...mapActions('EditItem', ['setOrganization', 'editOrganization', 'initOrgaEditLists', 'addOrganization']),
        goBack(){
            router.push({name:'organisations-list', params: {
                previousPage: this.previousPage
            }})
        },
        showDeleteModal(id){
            this.selectedID = id;
            this.$refs['delete-modal-detail'].show();
        },
        async deleteItem(){
            const response = await this.$apollo.query({
                query: FC_DELETE_ITEM("organisation"),
                variables: {
                    id: this.selectedID
                }
            })
            this.selectedID = "";
            this.$refs['delete-modal-detail'].hide();
            this.getList();
        },
        mapFields(){
            this.fields = this.getOrganization;
            this.userList = this.getUserList;
            // this.countryList = this.getCountryList;
        },
        createOrEdit(e){
            e.preventDefault();
            if(this.creating){
                this.confirmCreate();
            } else {
                this.confirmEdit();
            }
        },
        confirmEdit(){
            if (this.allValid && this.delayer){
                this.editOrganization(this.fields);
                this.delayer = false;
                setTimeout(this.resetDelayer, 500);
            }
        },
        confirmCreate(){
            if (this.allValid && this.delayer){
                this.delayer = false;
                this.addOrganization(this.fields);
                this.creating = false;
                setTimeout(this.resetDelayer, 500);
            }
        },
        resetDelayer(){
            this.delayer = true;
        }
    }
}
</script>
<style lang="scss">
.organisation-card{
    > div{
        @apply my-5;
    }
    .left, .right{
        @apply w-full;
        .input-group--start {
            @apply justify-start;
        }
        > div {
            @apply flex flex-row justify-around items-center my-5 flex-no-wrap;
            > label {
                @apply text-right mr-5 w-1/5 text-xs;
            }
            > input{
                &:not(.is-invalid){
                    @apply border-line-top;
                }
                @apply w-4/5 flex-auto;
                border-radius: 0.275rem !important;
            }
            .twn-select {
                @apply w-4/5;
                .vs__dropdown-toggle {
                    @apply border-line-top;
                }
                &.is-invalid{
                    .vs__dropdown-toggle {
                        border-color: #dc3545;
                    }
                }
            }
            &.town-group{
                > input {
                    @apply w-4/5;
                }
                .twn-select{
                    @apply hidden;
                }
            }
            &.town-group-mobile{
                .twn-select{
                    @apply w-4/5;
                }
            }
        }
        @screen xs {
            > div > label {
                @apply text-sm;
            }
            > div.town-group{
                > input {
                    width: 37%;
                }
                .twn-select{
                    @apply flex-no-wrap block ml-4;
                }
            }
            > div.town-group-mobile {
                @apply hidden;
            }
        }
    }
    @screen md {
        .left, .right {
            @apply w-1/2;
            .button-holder{
                @apply flex flex justify-end items-end;
            }
        }
    }
    .right > div .twn-select{
        @apply w-2/5;
    }
    hr{
        box-sizing: border-box;
        border: 1px solid;
        @apply border-line-top rounded-lg;
    }
    .stars{
        .star{
            @apply w-8 mx-1 fill-current text-gray-star;
            &.complete{
                @apply text-principal-selected;
            }
        }
    }
}

.table_list_in{
    @apply mt-0 rounded-lg py-5 px-8;
    @screen md{
        @apply mt-5;
    }
    // box-shadow: $cardShadow;
    thead{
        th{
            padding-bottom: 2rem !important;
            @apply uppercase font-principal-bold px-0 text-xs py-10;
            @screen sm {
                @apply text-sm;
            }
            &:last-child{
                @apply w-1/4;
            }
        }
        th:not(:hover){
            background-image: none !important;
        }
    }
    tbody, thead{
        th, td{
            @apply py-5;
        }
    }
    tbody{
        @apply text-principal-list-item;
        tr {
            &:not(:last-child){
                border-bottom: 1px solid;
                @apply border-line-top;
            }
            &:not(:hover) .show-actions{
                opacity: 0;
            }
            td {
                @apply px-0;
            }
        }
    }
    &.mobile{
        @apply px-4;
        box-shadow: none;
        min-height: 700px;
        .edit-menu{
            right:0px;
            top: -10px;
        }
    }
}
</style>