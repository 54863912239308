import { GC_GET_APPLICATION_ID, GC_GET_EVENT_BY_ID } from "@/graphql/rxs";
import rxsApollo from "@/rxsApolloClient";

export default {
  namespaced: true,
  state: {
    applicationId: '',
  },

  mutations: {
    SET_ITEM(state, [key, value]) {
      state[key] = value;
    }
  },

  actions: {
    async init({ commit }) {
      const response = await rxsApollo.query({ query: GC_GET_APPLICATION_ID });
      commit('SET_ITEM', ['applicationId', response.data.application[0].id]);
    },

    async getEventById({ commit }, id) {
      const response = await rxsApollo.query({ query: GC_GET_EVENT_BY_ID, variables: { id } });
      return response.data.events_by_pk
    }
  },
};
