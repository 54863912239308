<template>
    <div class="game-simple">
        <!-- Hotspots editor -->
        <div class="flex">
            <div class="hotspots" :class="{ 'empty': !backgroundImage }">
                <div
                class="hotspot"
                v-for="(hotspot, index) in hotspots"
                :key="index"
                :style="{ top: (hotspot.line * 100) + '%', left: (hotspot.column * 100) + '%' }"
                draggable="false"
                @mousedown="selectHotspot($event, index)"
                @mouseup="unselectHotspot"
                @mouseout="unselectHotspot"
                @mousemove="moveHotspot($event, index, hotspot)"
                >
                    <span draggable="false">{{ getHotspotIndexLabel(index) }}</span>
                </div>

                <img
                class="cursor-copy"
                draggable="false"
                :src="backgroundImage"
                @click="addHotspot">
            </div>

            <div class="w-1/5">
                <button
                class="twn-button text-xs ml-4"
                @click="openHotspotBackgroundPanel">
                    Remplacer l'image
                </button>

                <button
                :disabled="!backgroundImage"
                @click="addHotspot(null)"
                class="twn-button text-xs ml-4 mt-4">
                    Ajouter un hotspot
                </button>
            </div>
        </div>

        <p class="mt-4 mb-8">Glisser déposer chaque spot à l'endroit voulue</p>

        <!-- Hotspots/Questions list -->
        <Draggable v-model="hotspots" handle=".handle" v-bind="dragOptions">
            <div class="" v-for="(question, index) in hotspots" :key="index">
                <Hotspot
                class="my-5"
                :question="question"
                :identifier="getHotspotIndexLabel(index)"
                @delete-question="deleteQuestion(index)"
                @update-question="updateQuestion(index, $event)" />
            </div>
        </Draggable>

        <!-- Media panel -->
        <RightPanel ref="rightPanel" title="Choix d'image">
            <Medias
                :defaultTypeFilterSlug="['image']"
                :selectOnly="true"
                :draggable-asset="false"
                @select-media="updateHotspotBackground"
            ></Medias>
        </RightPanel>
    </div>
</template>
<script>

import { mapState } from 'vuex'

import Draggable from 'vuedraggable'

import Hotspot from '@/components/Games/Hotspot'
import RightPanel from "@/components/RightPanel"
import Medias from "@/views/Medias"

export default {
    name: 'Hotspots',
    components: {
        Hotspot,
        Draggable,
        RightPanel,
        Medias
    },
    props: {
		game: {
			type: Object,
			required: true,
			default: null
		}
    },
    data() {
        return {
            selectedHotspot: null,
            selectedHotspotLastEvent: null,
            dragOptions: {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            }
        }
    },
    computed: {
        ...mapState('Assets', {
            assets(state) {
                if (!state.assets || state.assets.length <= 0) return {}

                return state.assets.reduce((dict, asset) => {
                    dict[asset.id] = asset

                    return dict
                }, {})
            }
        }),
        hotspots: {
            get() {
                if (!this.game)
                    return []

                return JSON.parse(JSON.stringify(this.game.questions));
            },
            set(value){
                this.$emit('update-all-questions', value)
            }
        },
        backgroundImage() {
            if (!this.game.medias || this.game.medias.length <= 0 || !this.game.medias[0].media_id || !this.assets[this.game.medias[0].media_id])
                return null

            return this.assets[this.game.medias[0].media_id].url
        }
    },
    methods: {
        getHotspotIndexLabel(index) {
            let label = ""

            const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
            for (var i = 0; i < Math.ceil((index + 1) / letters.length); i++) {
                label += letters[index % letters.length]
            }

            return label
        },
        addHotspot(event) {
            if (!this.backgroundImage)
                return

            let top = 0.4
            let left = 0.4

            if (event) {
                const container = event.currentTarget

                top = ((event.offsetY - (container.clientHeight * 0.075)) / container.clientHeight)
                left = ((event.offsetX - (container.clientWidth * 0.075)) / container.clientWidth)
            }

            // Add a question to represent a hotspot
            const question = {
                text: '',
                correction: '',
                help: '',
                media_id: null,
                line: top,
                column: left,
                answers: [],
            }

            this.$emit('add-questions', [ question ])
        },
        selectHotspot(event, index) {
            this.selectedHotspot = index
            this.selectedHotspotLastEvent = event
        },
        unselectHotspot() {
            if (event.buttons > 0)
                return

            this.selectedHotspot = null
        },
        moveHotspot(event, index, hotspot) {
            if (this.selectedHotspot != index || event.buttons <= 0)
                return

            // Compute delta since last event
            const parent = event.currentTarget.parentElement
            
            const deltaX = (event.clientX - this.selectedHotspotLastEvent.clientX) / parent.clientWidth
            const deltaY = (event.clientY - this.selectedHotspotLastEvent.clientY) / parent.clientHeight

            // Check if the new coordinates are out of container
            const maxTop = (parent.clientHeight - event.currentTarget.clientHeight) / parent.clientHeight
            const maxLeft = (parent.clientWidth - event.currentTarget.clientWidth) / parent.clientWidth

            let column = (hotspot.column + deltaX)
            let line = (hotspot.line + deltaY)

            if (column < 0) {
                column = 0
            } else if (column > maxLeft) {
                column = maxLeft
            }

            if (line < 0) {
                line = 0
            } else if (line > maxTop) {
                line = maxTop
            }

            // Update hotspot coordinates
            this.$emit('update-question', index, {
                column,
                line
            })

            this.selectedHotspotLastEvent = event
        },
        openHotspotBackgroundPanel() {
            this.$refs['rightPanel'].show()
        },
        updateHotspotBackground(asset){
            if (asset.type.slug != 'image')
                return

            this.$emit('update-game-media', asset.id)
            this.$refs['rightPanel'].hide()
        },
        updateQuestion(i, data){
            this.$emit('update-question', i, data)
        },
        deleteQuestion(i){
            this.$emit('delete-questions', [i])
        }
    }
}
</script>

<style lang="scss">
    .hotspots {
        @apply w-4/5 relative;
        background-color: #232329;
        user-select: none;
        
        &.empty {
            height: 600px;
        }

        img {
            user-select: none;
            min-width: 100%;
        }
    }

    .hotspot {
        @apply absolute flex justify-center items-center rounded-full text-3xl font-principal-bold cursor-grab;
        width: 15%;
        background-color: rgba(255, 255, 255, 0.6);

        &:after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        span {
            user-select: none;
        }
    }
</style>