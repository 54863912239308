import { getUsersAnalytics, usersAnalytics } from './usersData'
import { groupAnalytics } from './groupsData'

export default {
	name: 'Données de connexion',
	content: [
		{ cells: ['Clients', '', 'Promotions', '', '', '', 'Utilisateurs'] },
		{
			table: 'groupsSortedByCustomer',
			headers: ['ID client', 'Nom client', 'ID promo', 'Nom promo', 'Journey AWKN', 'Date de début', 'Inscrits', 'Actifs', 'Capsules envoyées', 'Tps passé moyen', 'Taux d\'ouverture', 'Taux de clic'],
			row: (group, data) => {
				const customer = data.customersById[group.customer_id]

				// Get users analytics
				const users = data.usersByGroupId[group.id] || []
				const analytics = getUsersAnalytics(users)
				const usersData = usersAnalytics(analytics)

				// Get group analytics
				const groupData = groupAnalytics(analytics)

				return [
					{ value: customer?.identifier || '' },
					{ value: customer?.name || '' },
					'identifier',
					'name',
					'awkn_journey_id',
					{ value: new Date(group.start_date).getTime(), type: 'date' },
					{ value: users.length },
					{ value: usersData.activeCount },
					{ value: (groupData.sentSteps || 0) + ' / ' + groupData.stepsCount },
					{ value: (usersData.averageTimeInSeconds || 0) * 1000, type: 'duration' },
					{ value: usersData.openRate, type: 'percentage' },
					{ value: usersData.clickRate, type: 'percentage' },
				]
			},
		}
	],
}